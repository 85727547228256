// This file is a start for the Mixpanel implementation.

import mixpanel from 'mixpanel-browser';
const mixpanelToken = process.env.VUE_APP_MIXPANEL_TOKEN;
const mixpanelDebug = process.env.VUE_APP_ENVIRONMENT != 'production';
mixpanel.init(mixpanelToken, {debug: mixpanelDebug});

const mappedActions = {
  // DEVICE_TYPE_CHANGED: '',
  // ELEMENT_CLICKED: '',
  // ELEMENT_CLONED: '',
  // ELEMENT_CREATED: '',
  // ELEMENT_DELETED: '',
  // ELEMENT_MOVED_DOWN: '',
  // ELEMENT_MOVED_UP: '',
  // ELEMENT_POSITION_CHANGED: '',
  // ELEMENT_PROPERTY_CHANGED: '',
  // ELEMENT_PROPERTY_RESET: '',
  // ELEMENT_SAVED: '',
  // ELEMENT_SORT_ENDED: 'ELEMENT_SORTED',
  // FUNNEL_CUSTOM_DOMAIN_UPDATED: '',
  // FUNNEL_DEPLOYED: '',
  FUNNEL_LOADED: 'Editor Opened',
  // FUNNEL_PROPERTY_CHANGED: '',
  // FUNNEL_SAVED: '',
  PAGE_CLONED: 'Page Cloned',
  PAGE_CREATED: 'Page Created',
  PAGE_DELETED: 'Page Deleted',
  PAGE_INSERTED: 'Page Inserted',
  // PAGE_PROPERTY_CHANGED: '',
  // PAGE_SETTINGS_UPDATED: '',
  POPUP_RESOURCE_INSERTED: 'Popup Created',
  // POPUP_SETTINGS_UPDATED: '',
  POPUP_DELETED: 'Popup Deleted',
  SPLIT_TEST_CREATED: 'Split Test Created',
  SPLIT_TEST_STOPPED: 'Split Test Stopped',
  SPLIT_TEST_STARTED: 'Split Test Started',
};

const mappedActionsKeys = Object.keys(mappedActions);
let userId = false;

export function MixpanelVuex(store) {
  // Actions
  store.subscribeAction(function after({payload: {type, payload}}, state) {
    const logAction = mappedActionsKeys.includes(type);

    if (logAction) {
      const potentiallyTransformedType = mappedActions[type] || type;

      if (userId) {
        mixpanel.track(potentiallyTransformedType, {
          distinct_id: userId,
        });
      }
    }

    // Set userId
    if (type === 'user/USER_LOADED') {
      let data = {};
      try {
        data = payload.user.data;
      } catch (e) {
        console.log(e);
      }
      if (userId) return;
      if (!data._id) return;

      userId = data._id;

      // Identify User
      mixpanel.people.set(userId, {
        distinct_id: data._id,
        is_print_user: data.print_user_id ? true : false,
        $first_name: data.first_name,
        $last_name: data.last_name,
        $created: data.created_at,
        $email: data.email,
      });

      // Track User
      mixpanel.track('Converdy Loaded', {
        distinct_id: userId,
      });
    }
  });
}
