import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';

import pathTo from '@converdy/utils/path-to';
import arrayInsert from '@converdy/utils/array-insert';
import arrayRemoveIndex from '@converdy/utils/array-remove-index';
import arrayLast from '@converdy/utils/array-last';
import deepValues from '@converdy/utils/deep-values';
import vueSetDeep from '@converdy/c-utils/vueSetDeep';

import {
  ELEMENTS_LOADED,
  ELEMENT_DELETED,
  ELEMENT_MOVED_UP,
  ELEMENT_MOVED_DOWN,
  ELEMENT_POSITION_CHANGED,
  ELEMENT_CREATED,
  PAGE_CREATED,
  PAGE_DELETED,
  PAGES_LOADED,
  PAGE_RESOURCE_LOADED,
  POPUPS_LOADED,
  POPUP_RESOURCE_LOADED,
  POPUP_DELETED,
} from '../action-types';
const getChildIds = require('@converdy/c-utils/get-child-ids');

export const initial = {};

export const state = cloneDeep(initial);

function handleElementsLoaded({commit}, {payload: {elements}}) {
  Object.values(elements).forEach((element) => {
    const {slots, id, parentId} = element;
    commit(ADD, {id, slots, parentId});
  });
}

function handlePageDeleted({commit, getters}, {payload: {pageId}}) {
  const childIds = getters.childIdsById(pageId);

  childIds.forEach((childId) => {
    commit(DELETE, {id: childId});
  });

  commit(DELETE, {id: pageId});
}

function handlePageCreated({commit}, {payload: {page, elements, funnelId}}) {
  /* TODO: handle funnel-page relationship through funnelId */
  Object.values(elements).forEach(({id, parentId, slots}) => {
    commit(ADD, {id, parentId, slots});
  });

  const {id, slots} = page;

  commit(ADD, {id, parentId: funnelId, slots});
}

function handlePagesLoaded({commit}, {payload: {pages}}) {
  Object.values(pages).forEach((page) => {
    const {slots, id, parentId} = page;

    commit(ADD, {id, slots, parentId});
  });
}

function handlePageResourceLoaded(
  {commit},
  {
    payload: {
      page: {id, slots, parentId},
    },
  },
) {
  commit(ADD, {id, slots, parentId});
}

// Popups
function handlePopupDeleted({commit, getters}, {payload: {popupId}}) {
  const childIds = getters.childIdsById(popupId);

  childIds.forEach((childId) => {
    commit(DELETE, {id: childId});
  });

  commit(DELETE, {id: popupId});
}

function handlePopupsLoaded({commit}, {payload: {popups}}) {
  Object.values(popups).forEach((resource) => {
    const {slots, id, parentId} = resource;

    commit(ADD, {id, slots, parentId});
  });
}

function handlePopupResourceLoaded(
  {commit},
  {
    payload: {
      popup: {id, slots, parentId},
    },
  },
) {
  commit(ADD, {id, slots, parentId});
}

// Elements

function handleElementDeleted({commit, getters}, {payload: {id}}) {
  commit(EXTRACT, {id});

  const slots = getters.slotsById(id);

  if (slots) {
    function recurse(rootId) {
      const slots = getters.slotsById(rootId);

      if (!slots) return [];

      const ids = deepValues(slots);

      return [...ids, ...ids.map(recurse).flat(1)];
    }

    const childIds = recurse(id);

    childIds.forEach((childId) => {
      commit(DELETE, {id: childId});
    });
  }

  commit(DELETE, {id});
}

function handleElementCreated(
  {commit},
  {payload: {index, childId, parentId, slotName, elements}},
) {
  Object.values(elements).forEach((elements) => {
    const {id, slots, parentId} = elements;
    commit(ADD, {id, slots, parentId});
  });
  commit(INSERT, {index, childId, parentId, slotName});
}

function handleElementPositionChanged({getters, commit}, {payload}) {
  const {to, toSlot, from, fromSlot, newIndex, oldIndex} = payload;

  const {slots} = getters.nestingById(from);

  const removedElementId = get(slots, `${fromSlot}[${oldIndex}]`);

  const extraction = {id: removedElementId};

  const insertion = {
    parentId: to,
    slotName: toSlot,
    childId: removedElementId,
    index: newIndex,
  };

  commit(EXTRACT, extraction);

  commit(INSERT, insertion);
}

const handleElementMovedUpOrDown =
  (direction) =>
  ({commit, getters}, {payload: {id}}) => {
    /* can't move it up if it's already at the top */
    if (direction === 'up' && getters.isFirstInSlot(id)) {
      return;
    }

    /* can't move it down if it's already at the bottom */
    if (direction === 'down' && getters.isLastInSlot(id)) {
      return;
    }

    /* e.g. [ 'content', '0' ] */
    const pathToSlotPosition = getters.pathToSlotPosition(id);

    /* e.g. [ 'content' ] */
    const pathToSlot = pathToSlotPosition.slice(0, -1);

    const index = arrayLast(pathToSlotPosition);

    const indexToBeMovedTo =
      direction === 'up' ? index - 1 : index + 1; /* direction === 'down' */

    const {parentId} = getters.nestingById(id);

    commit(EXTRACT, {id});

    commit(INSERT, {
      parentId,
      childId: id,
      slotName: pathToSlot.join('.'),
      index: indexToBeMovedTo,
    });
  };

/* meh, not too pretty, could be uglier tho */
const handleElementMovedUp = handleElementMovedUpOrDown('up');

const handleElementMovedDown = handleElementMovedUpOrDown('down');

export const actions = {
  [ELEMENTS_LOADED]: handleElementsLoaded,

  [PAGE_CREATED]: handlePageCreated,

  [PAGE_DELETED]: handlePageDeleted,

  [PAGES_LOADED]: handlePagesLoaded,

  [PAGE_RESOURCE_LOADED]: handlePageResourceLoaded,

  [POPUP_DELETED]: handlePopupDeleted,

  [POPUPS_LOADED]: handlePopupsLoaded,

  [POPUP_RESOURCE_LOADED]: handlePopupResourceLoaded,

  [ELEMENT_DELETED]: handleElementDeleted,

  [ELEMENT_MOVED_UP]: handleElementMovedUp,

  [ELEMENT_MOVED_DOWN]: handleElementMovedDown,

  [ELEMENT_POSITION_CHANGED]: handleElementPositionChanged,

  [ELEMENT_CREATED]: handleElementCreated,
};

const prefix = (s) => `SLOT/${s}`;

const ADD = prefix('ADD');
const DELETE = prefix('DELETE');
const INSERT = prefix('INSERT');
const MOVE = prefix('MOVE');
const EXTRACT = prefix('EXTRACT');

function add(state, {id, slots, parentId}) {
  Vue.set(state, id, {
    parentId,
    slots,
  });
}

function del(state, {id}) {
  Vue.delete(state, id);
}

function insert(nesting, payload) {
  // return;
  const {parentId, slotName, childId, index} = payload;

  const {slots} = nesting[parentId];

  const currentSlot = get(slots, slotName);

  const newSlot = arrayInsert(currentSlot, index, childId);

  const newSlots = set(slots, slotName, newSlot);

  Vue.set(nesting[parentId], 'slots', cloneDeep(newSlots));
  Vue.set(nesting[childId], 'parentId', parentId);
}

function extract(nesting, {id}) {
  const {parentId} = nesting[id];

  const {slots} = nesting[parentId];

  const pathToChildId = pathTo(id, slots);

  const indexOfChildInSlot = arrayLast(pathToChildId);

  const pathToSlot = pathToChildId.slice(0, -1);

  const slot = get(slots, pathToSlot);

  const slotWithoutChildId = arrayRemoveIndex(slot, indexOfChildInSlot);

  vueSetDeep(
    Vue,
    nesting[parentId].slots,
    pathToSlot.join('.'),
    cloneDeep(slotWithoutChildId),
  );
}

export const mutations = {
  [ADD]: add,

  [DELETE]: del,

  [INSERT]: insert,

  [MOVE]() {},

  [EXTRACT]: extract,
};

export const getters = {
  nestingById: (nesting) => (id) => nesting[id],

  slotsById: (_, getters) => (id) => getters.nestingById(id).slots,

  isLastInSlot: (nesting, getters) => (id) => {
    const {
      [id]: {parentId},
    } = nesting;

    const {
      [parentId]: {slots},
    } = nesting;

    const pathToSlotPosition = getters.pathToSlotPosition(id);

    const pathToSlot = pathToSlotPosition.slice(0, -1);

    const index = arrayLast(pathToSlotPosition);

    const slot = get(slots, pathToSlot);

    return index === slot.length - 1;
  },

  isFirstInSlot: (nesting, getters) => (id) => {
    const pathToSlotPosition = getters.pathToSlotPosition(id);

    return arrayLast(pathToSlotPosition) === 0;
  },

  pathToSlotPosition: (nesting) => (id) => {
    const {parentId} = nesting[id];

    const {slots} = nesting[parentId];

    return pathTo(id, slots);
  },

  pathToSlot: (nesti, getters) => (id) => {
    return getters.pathToSlotPosition(id).slice(0, -1);
  },

  childIdsById: (nesting) => (id) => getChildIds(id, nesting),
};

export default {
  state,
  actions,
  mutations,
  getters,
};

/*

ham.dispatch('ELEMENT_POSITION_CHANGED', {
  payload: {
    from: 'c1kwoziqrb',
    fromSlot: 'content',
    newIndex: 1,
    oldIndex: 0,
    to: 'c1kwq8wrc6',
    toSlot: 'content',
  },
});

ham.dispatch('ELEMENT_POSITION_CHANGED', {
  payload: {
    from: 'c1kwq8wrc6',
    fromSlot: 'content',
    newIndex: 0,
    oldIndex: 1,
    to: 'c1kwq8wrc6',
    toSlot: 'content',
  },
});

// Instant problem 


ham.dispatch('ELEMENT_POSITION_CHANGED', {
  payload: {
    from: 'c1kwq8sepe',
    fromSlot: 'columns.0',
    newIndex: 0,
    oldIndex: 0,
    to: 'c1kwoziqrb',
    toSlot: 'content',
  },
});

*/
