import axios from 'axios';
import toMutation from '../to-mutation'
import { USER_LOADED } from '../action-types'

import {
	userLoaded
} from '../action-creators'

const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const state = {
  currentUser: {},
};

export const getters = {
  currentUser(state) {
    return state.currentUser;
  },
};

export const mutations = {
  [SET_CURRENT_USER](state, {user}) {
    state.currentUser = user.data;
  },
};

export const actions = {

  [USER_LOADED]: toMutation(SET_CURRENT_USER),
  
  async loadCurrentUser({getters, dispatch, commit}) {

    try {
      const user = await axios.get('/users/me');
      dispatch(userLoaded({
        user: user
      }))

    } catch (err) {
    }
  }
  
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
