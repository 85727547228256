import cloneDeep from 'lodash/cloneDeep';
import arrayToggle from '@converdy/utils/array-toggle';
import toObject from '@converdy/utils/to-object';
import set from 'lodash/set';
import get from 'lodash/get';

import {
  DEVICE_TYPE_CHANGED,
  DIALOG,
  EDITOR_LOADED,
  EDITOR_MOUNTED,
  EDITOR_UNMOUNTED,
  ELEMENT_CLICKED,
  ELEMENT_DELETED,
  ELEMENT_INSERTION_DIALOG_OPENED,
  ELEMENT_SORT_ENDED,
  ELEMENT_SORT_STARTED,
  FUNNEL_DEPLOYED,
  FUNNEL_SAVED,
  HIGHLIGHTED_NODES,
  UNSELECT_ELEMENTS,
  ACTIVE_DOCUMENT_CHANGED,
  CHANGE_ACTIVE_DOCUMENT,
  SIDEBAR_VIEW_CHANGED,
  HEADER_CLICKED,
} from '../action-types';

import {
  funnelLoaded,
  pagesLoaded,
  editorLoaded,
  elementsLoaded,
  popupsLoaded,
  unselectElements,
  sidebarViewChanged,
} from '../action-creators';

import {
  getFunnel,
  getFunnelPages,
  getFunnelPopups,
  getFunnelElements,
  saveFunnel,
  publishFunnel,
} from '../../service.js';
import toMutation from '../to-mutation';
import {arrayRemove} from '@converdy/utils';

const SELECT_ELEMENT = 'SELECT_ELEMENT';
const SET_ACTIVE_DEVICE_TYPE = 'SET_ACTIVE_DEVICE_TYPE';
const TOGGLE_ELEMENT_SELECT = 'TOGGLE_ELEMENT_SELECT';
const UNSELECT_ELEMENT = 'UNSELECT_ELEMENT';
const UPDATE_CONTROL_PROPERTIES = 'UPDATE_CONTROL_PROPERTIES';
const SET_LOADING = 'SET_LOADING';
const SET_LOADED = 'SET_LOADED';
const SET_SAVING = 'SET_SAVING';
const SET_SAVED = 'SET_SAVED';
const OPEN_SECTION_DIALOG = 'OPEN_SECTION_DIALOG';
const OPEN_ELEMENT_DIALOG = 'OPEN_ELEMENT_DIALOG';
const UPDATE_DIALOG = 'UPDATE_DIALOG';
const SET_SIDEBAR_VIEW = 'SET_SIDEBAR_VIEW';
const UNSAVED_CHANGES = 'UNSAVED_CHANGES';

export const SYNC_UNDO_REDO = 'SYNC_UNDO_REDO';

export const initial = {
  unsavedChanges: false,
  activeDocument: {
    type: 'page',
    id: false,
  },
  activeDeviceType: 'desktop',
  undoRedo: {done: {}, undone: {}},
  elementsSorting: false,
  selectedElementIds: [],
  isLoading: true,
  isLoaded: false,
  isSaving: false,
  isSaved: false,
  highlightedNodes: null,
  dialogs: {
    elementInsertion: {
      open: false,
    },
    sectionInsertion: {
      open: false,
    },
    addIntegrationDialog: {
      open: false,
    },
    newPageDialog: {
      open: false,
    },
  },
  sidebarView: 'overview',
};

export const state = cloneDeep(initial);

export const actions = {
  async [EDITOR_MOUNTED]({commit, dispatch}, {payload: {projectId, funnelId}}) {
    commit(SET_LOADING, true);

    try {
      const [{data: funnel}, {data: pages}, {data: popups}, {data: elements}] =
        await Promise.all([
          getFunnel(projectId, funnelId),
          getFunnelPages(projectId, funnelId),
          getFunnelPopups(projectId, funnelId),
          getFunnelElements(projectId, funnelId),
        ]);

      if (!funnel) throw new Error('NoFunnel');

      dispatch(funnelLoaded({funnel}));

      if (pages) {
        dispatch(pagesLoaded({pages}));
      }

      if (popups) {
        dispatch(popupsLoaded({popups}));
      }

      if (elements) {
        dispatch(elementsLoaded({elements}));
      }

      dispatch(editorLoaded(funnel._id));
      return true;
    } catch (error) {
      console.error(error);
    }
  },

  [ACTIVE_DOCUMENT_CHANGED]: ({commit, getters}, {payload: {type, id}}) => {
    /* If only type is defined */
    if ((type === 'page') & !id) {
      id = get(getters.pagesByFunnelId(), [0, 'id'], false);
    }

    if ((type === 'popup') & !id) {
      id = get(getters.popupsByFunnelId(), [0, 'id'], false);
    }

    commit(CHANGE_ACTIVE_DOCUMENT, {type, id});
  },

  [DEVICE_TYPE_CHANGED]: toMutation(SET_ACTIVE_DEVICE_TYPE),

  [SIDEBAR_VIEW_CHANGED]: ({commit, state}, {payload}) => {
    commit(SET_SIDEBAR_VIEW, payload);
  },

  [HEADER_CLICKED]: ({commit, dispatch, state}) => {
    // If element selected, unselect
    const [currentlySelectedElementId] = state.selectedElementIds;

    if (currentlySelectedElementId) {
      dispatch(unselectElements());
    }

    dispatch(sidebarViewChanged('headerSelected'));
  },
  [ELEMENT_CLICKED]: (
    {commit, state, dispatch, getters},
    {payload: {elementId}},
  ) => {
    const selectedElement = getters.selectedElement;
    const selectedElementId = selectedElement?.id;
    const isSection = selectedElement?.name === 'CSection';

    if (elementId === selectedElementId) return;

    if (selectedElement && !isSection) {
      dispatch(unselectElements());
    } else {
      dispatch(unselectElements());
      commit(SELECT_ELEMENT, {elementId});
      dispatch(sidebarViewChanged('selected'));
    }
  },

  [DIALOG]: toMutation(UPDATE_DIALOG),

  [ELEMENT_INSERTION_DIALOG_OPENED]: toMutation(OPEN_ELEMENT_DIALOG),

  [ELEMENT_SORT_ENDED]: toMutation(ELEMENT_SORT_ENDED),

  [ELEMENT_SORT_STARTED]: toMutation(ELEMENT_SORT_STARTED),

  [HIGHLIGHTED_NODES]: toMutation(HIGHLIGHTED_NODES),

  async [FUNNEL_SAVED](
    {commit, rootState, getters},
    {payload = {save: false}},
  ) {
    const config = payload;
    const {
      projectId,
      funnelId,
      elementsByFunnelId,
      pagesByFunnelId,
      popupsByFunnelId,
    } = getters;

    const {settings, theme} = getters.funnelById(funnelId);

    const pages = pagesByFunnelId(funnelId);

    const popups = popupsByFunnelId(funnelId);

    const elements = toObject(
      (element) => element.id,
      (element) => element,
    )(elementsByFunnelId(funnelId));

    const saveObject = {
      settings,
      theme,
      popups,
      pages,
      elements,
      header_scripts: rootState.header_scripts,
      footer_scripts: rootState.footer_scripts,
      // Used for template saving, not to be stored on funnel.
      config,
    };

    commit(SET_SAVING, true);

    try {
      await saveFunnel(projectId, funnelId, saveObject);

      commit(SET_SAVED, true);
    } catch (e) {
      throw e;
    }

    commit(SET_SAVING, false);

    return {projectId, funnelId, saveObject};
  },

  [ELEMENT_DELETED]({dispatch}) {
    dispatch(unselectElements());
  },

  async [FUNNEL_DEPLOYED]({rootState, getters}) {
    const {projectId, funnelId, elementsByFunnelId, pagesByFunnelId} = getters;
    const {settings, theme} = getters.funnelById(funnelId);

    const pages = toObject(
      (page) => page.id,
      (page) => page,
    )(pagesByFunnelId(funnelId));

    const elements = toObject(
      (element) => element.id,
      (element) => element,
    )(elementsByFunnelId(funnelId));

    const funnelObject = {
      settings,
      theme,
      popups: rootState.popups,
      pages,
      elements,
      header_scripts: rootState.header_scripts,
      footer_scripts: rootState.footer_scripts,
    };
    try {
      await publishFunnel(projectId, funnelId, funnelObject);
    } catch (e) {
      console.error(e);
    }
  },

  // [UNSELECT_ELEMENTS]: toMutation(UNSELECT_ELEMENTS),
  [UNSELECT_ELEMENTS]({commit, dispatch}, {payload}) {
    dispatch(sidebarViewChanged('overview'));
    commit(UNSELECT_ELEMENTS, payload);
  },

  [UPDATE_CONTROL_PROPERTIES]: toMutation(UPDATE_CONTROL_PROPERTIES),

  [EDITOR_LOADED]({commit}) {
    commit(SET_LOADING, false);
    commit(SET_LOADED, true);
  },

  [EDITOR_UNMOUNTED]({commit}) {
    commit(SET_LOADED, false);
  },
};

export const mutations = {
  [UNSAVED_CHANGES](state, payload) {
    state.unsavedChanges = payload;
  },

  [UPDATE_DIALOG](state, {dialog, open, options}) {
    if (state.dialogs[dialog]) {
      set(state.dialogs[dialog], 'open', open);
      set(state.dialogs[dialog], 'options', options);
    }
  },

  [ELEMENT_SORT_STARTED](state) {
    state.elementsSorting = true;
  },

  [ELEMENT_SORT_ENDED](state) {
    state.elementsSorting = false;
  },

  [HIGHLIGHTED_NODES](state, {selector, label}) {
    state.highlightedNodes = {selector, label};
  },

  [SET_ACTIVE_DEVICE_TYPE](state, {deviceType}) {
    state.activeDeviceType = deviceType;
  },

  [SELECT_ELEMENT](state, {elementId}) {
    state.selectedElementIds = arrayToggle(state.selectedElementIds, elementId);
  },

  [TOGGLE_ELEMENT_SELECT](state, {elementId}) {
    state.selectedElementIds = arrayToggle(state.selectedElementIds, elementId);
  },

  [UNSELECT_ELEMENT](state, {id}) {
    if (state.selectedElementIds.includes(id)) {
      state.selectedElementIds = arrayRemove(state.selectedElementIds, id);
    }
  },

  [UNSELECT_ELEMENTS](state) {
    state.selectedElementIds = [];
  },

  [SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },

  [SET_LOADED](state, isLoaded) {
    state.isLoaded = isLoaded;
  },

  [SET_SAVING](state, isSaving) {
    state.isSaving = isSaving;
  },

  [SET_SAVED](state, isSaved) {
    state.isSaved = isSaved;
  },

  // [SYNC_UNDO_REDO](state, undoRedo) {
  //   state.undoRedo = undoRedo;
  // },

  [SET_SIDEBAR_VIEW](state, sidebarView) {
    state.sidebarView = sidebarView;
  },

  [CHANGE_ACTIVE_DOCUMENT](state, {type, id}) {
    state.activeDocument = {
      type,
      id,
    };
  },
};

export const getters = {
  sidebarView: ({sidebarView}) => sidebarView,

  unsavedChanges: ({unsavedChanges}) => unsavedChanges,

  activeDocument: ({activeDocument}) => activeDocument,

  activeDeviceType: ({activeDeviceType}) => activeDeviceType,

  activePageId: (state, getters, rootState) =>
    state.activeDocument.type === 'page' && state.activeDocument.id,

  activeSplitTest: (state, getters, rootState) => {
    const page = getters.activePage;
    if (!page) return;

    if (page.isVariantFor) {
      return getters.pageById(page.isVariantFor).activeTest;
    }

    if (page.activeTest) {
      return page.activeTest;
    }
  },

  activePage: (state, getters, rootState, rootGetters) =>
    getters.pageById(getters.activePageId),

  activePageSlug: (state, getters, rootState, rootGetters) => {
    let page = getters.activePage;
    const homepageId = getters.homepageId;

    if (!page) {
      return '/';
    }

    if (page.isVariantFor) {
      page = getters.pageById(page.isVariantFor);
    }

    // If page.id is Homepage, return '/'
    if (
      homepageId === page.id ||
      (!homepageId && rootGetters.pagePositionById(page.id) === 0)
    ) {
      return '/';
    }

    // Return slug
    return '/' + page.settings.slug;
  },

  elementsSorting: ({elementsSorting}) => elementsSorting,

  projectId: (state, getters, rootState) => rootState.route.params.projectId,

  workspaceId: (state, getters, rootState) =>
    rootState.route.params.workspace_id,

  funnelId: (state, getters, rootState) => rootState.route.params.funnelId,

  activeFunnel: (state, getters, rootState, rootGetters) =>
    rootGetters.funnelById(getters.funnelId),

  isSelected:
    ({selectedElementIds}) =>
    (elementId) =>
      selectedElementIds.includes(elementId),

  selectedElements: (state, getters, rootState, rootGetters) =>
    state.selectedElementIds.map((id) => rootState.elements[id]),

  selectedElement: (state, {selectedElements}) => selectedElements[0], // returns first selected element

  selectedElementId: (state, getters) =>
    getters.selectedElement && getters.selectedElement.id, // returns first selected element

  editorIsLoading: (state) => state.isLoading,

  editorIsLoaded: (state) => state.isLoaded,

  funnelIsSaving: (state) => state.isSaving,

  funnelIsSaved: (state) => state.isSaved,

  highlightedNodes: (state) => state.highlightedNodes,

  newPageDialogIsOpen: (state) => state.dialogs.newPageDialog.open,

  sectionInsertionDialogIsOpen: (state) => state.dialogs.sectionInsertion.open,

  elementInsertionDialogIsOpen: (state) => state.dialogs.elementInsertion.open,

  addIntegrationDialogIsOpen: (state) =>
    state.dialogs.addIntegrationDialog.open,

  dialog: (state) => (dialog) => state.dialogs[dialog],

  dialogIsOpen: (state) => (dialogName) => state.dialogs[dialogName],

  dialogOptions: (state) => (dialogName) => state.dialogs[dialogName].options,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
