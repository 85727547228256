import cloneDeep from 'lodash/cloneDeep';
import createComparator from '@converdy/utils/create-numeric-comparator';

import {UNDOREDO_UNDO, UNDOREDO_REDO} from './action-types';

import {pageDeleted, pageCreated, unselectElements} from './action-creators';
import {SYNC_UNDO_REDO} from './modules/editor';
import {undoRedoCheckpointRestored} from './action-creators';

let resetStack = {};

const MAX_STACK_SIZE = 5000;

const CHECKPOINT_INTERVAL = 50;

export function isUndoable(action) {
  return Boolean(action.meta && action.meta.undoredo);
}

function findLastCheckpointIndex(stack) {
  const copy = [...stack];

  const index = copy
    .reverse()
    .findIndex((action) => action.meta.undoredo.checkpoint);

  return stack.length - 1 - index;
}

function splitStackOnLastCheckpoint(stack) {
  const lastCheckpointIndex = findLastCheckpointIndex(stack);

  const remainingActions = stack.slice(0, lastCheckpointIndex);

  const removedActions = stack.slice(lastCheckpointIndex);

  return [remainingActions, removedActions];
}

function generateReplayableStack(stacks) {
  /*
   * sometimes it happens that pointer reference values from the payload are
   * are assigned to a vuex state and later mutated, if we don't make an
   * immutable copy of the action here, it happens that we dispatch an
   * action which is not identical to the originally received one because
   * its contents have externally been mutated
   *
   * cloning the replayable action-stack before dispatching it as well as
   * cloning the action before pushing it into the undoredo stacks right
   * when it first arrives in the middleware ensures that we truly have a
   * copy of the initially dispatched action and that the above mentioned
   * can't happen
   */
  return cloneDeep(
    Object.values(stacks).flat(1).sort(createComparator('order', 'ascending')),
  );
}

export default function (store) {
  let done = {};

  let undone = {};

  let replayIsOngoing = false;

  let test = false;
  function getStackFromAction(action) {
    const activePageId = store.getters.activePageId;
    /*
     * if no stack has been explicitly declared on the action,
     * assign the active page id to the action by default
     */
    const {
      meta: {
        undoredo: {stack = activePageId},
      },
    } = action;

    return stack;
  }

  function replayActions(stack) {
    replayIsOngoing = true;

    stack.forEach((action) => {
      store.dispatch(action);
    });

    replayIsOngoing = false;
  }

  function undo(action) {
    const {
      payload: {stack: stackName = store.getters.activePageId},
    } = action;

    const resetStackFunction = resetStack[stackName];

    if (resetStackFunction) {
      resetStackFunction(store.state); // or potentially do in commit (would be neater)
    } else {
      console.error('NO RESET STACK FUNCTION');
    }

    const stack = done[stackName] || [];

    const [remainingDoneActions, undoneActions] =
      splitStackOnLastCheckpoint(stack);

    done[stackName] = remainingDoneActions;

    undoneActions.forEach((undoneAction) => {
      undone[stackName].push(undoneAction);
    });

    replayActions(generateReplayableStack(done[stackName]));

    store.dispatch(undoRedoCheckpointRestored({done, undone}));
  }

  function redo(action) {
    // Reset state
    const {
      payload: {stack: stackName = store.getters.activePageId},
    } = action;

    const resetStackFunction = resetStack[stackName];

    if (resetStackFunction) {
      resetStackFunction(store.state); // or potentially do in commit (would be neater)
    }

    const stack = undone[stackName] || [];

    const [remainingUndoneActions, redoneActions] =
      splitStackOnLastCheckpoint(stack);

    undone[stackName] = remainingUndoneActions;

    redoneActions.forEach((redoneAction) => {
      done[stackName].push(redoneAction);
    });

    replayActions(generateReplayableStack(done[stackName]));

    store.dispatch(undoRedoCheckpointRestored({done, undone}));
  }

  function regulateStack() {
    const actions = Object.values(done).flat(1);

    if (actions.length === MAX_STACK_SIZE) {
      const actionsAfterCheckpoint = actions.slice(CHECKPOINT_INTERVAL);

      Object.keys(done).forEach(function (stackName) {
        done[stackName] = actionsAfterCheckpoint.filter(
          (action) => action.payload.stack === stackName,
        );
      });
    }
  }

  function before({payload: action}) {
    if (replayIsOngoing) return;
    if (isUndoable(action)) {
      const stack = getStackFromAction(action);

      if (!done[stack]) {
        done[stack] = [];
      }

      undone[stack] = [];

      done[stack].push(cloneDeep(action));

      regulateStack();

      store.commit(SYNC_UNDO_REDO, {done, undone});
    }
  }

  function after(action, state) {
    if (replayIsOngoing) return;

    action = action.payload;

    if (action.type === UNDOREDO_UNDO) {
      undo(action);
      store.commit(SYNC_UNDO_REDO, {done, undone});
      return;
    }

    if (action.type === UNDOREDO_REDO) {
      redo(action);
      store.commit(SYNC_UNDO_REDO, {done, undone});
      return;
    }

    // Create undo stack functions

    if (action.type == 'EDITOR_LOADED') {
      // Reset done and undone
      done = {};
      undone = {};
      resetStack = {};

      // Funnel Styling
      resetStack.theme = (function (initialState) {
        const funnelId = action.funnelId;
        const theme = cloneDeep(initialState.funnel[funnelId].theme);

        return function (state) {
          state.funnel[funnelId].theme = cloneDeep(theme);
        };
      })(state);

      // Page created
      Object.values(state.pages.byId).forEach((page) => {
        addPageResetFunction(page);
      });
    }

    if (action.type == 'PAGE_CREATED') {
      addPageResetFunction(action.payload.page);
    }

    if (action.type == 'PAGE_DELETED') {
      if (!replayIsOngoing) {
        // Todo remove reset function reset function + stash
      }
    }

    if (action.type == 'ACTIVE_DOCUMENT_CHANGED') {
      const activeDocument = store.getters.activeDocument || {};

      // Regulate page stack,
      // Removes the undo redo stack for the page and creates a new resetPage function based on the current state
      if (activeDocument.type == 'page') {
        delete resetStack[activeDocument.id];
        delete undone[activeDocument.id];
        delete done[activeDocument.id];

        addPageResetFunction(store.getters.pageById(activeDocument.id), true);

        // sync undo redo
        store.commit(SYNC_UNDO_REDO, {done, undone});
      }
    }

    function addPageResetFunction(page) {
      // If there already is a reset function, return
      const id = page && page.id;

      if (resetStack[id]) {
        return;
      }

      // Create reset 'state' function for page
      resetStack[id] = (function () {
        const clonedPayload = cloneDeep({
          page: store.getters.pageById(page.id),
          elements: store.getters.elementsByPageId(page.id),
          funnelId: store.getters.funnelId,
        });

        return function (state) {
          replayIsOngoing = true;

          // UNSELECT POTENTIAL ELEMENTS
          store.dispatch(unselectElements());

          // GET PAGE POSITION
          const index = store.getters.pagePositionById(page.id);

          // Get current page settings
          const pageCopy = cloneDeep(store.getters.pageById(page.id));

          // Use latest slots
          pageCopy.slots = clonedPayload.page.slots;

          // DELETE PAGE
          store.dispatch(pageDeleted({pageId: page.id}));
          // RECREATE PAGE, PASS INDEX
          store.dispatch(
            pageCreated({
              ...cloneDeep({...clonedPayload, page: pageCopy}),
              index,
            }),
          );

          replayIsOngoing = false;
        };
      })();
    }
  }

  store.subscribeAction({before, after});
}
