import BaseRepository from './_base';
import axios from 'axios';

const projectRepository = new BaseRepository('projects');

projectRepository.create = async function (body = {}) {
  const {workspace_id} = body;
  if (!workspace_id) {
    throw new Error('No workspace defined!');
  }
  return (
    await axios.post(`/${this.resourceName}`, {
      ...body,
    })
  ).data;
};

projectRepository.updateSettings = async function (projectId, changes) {
  return (
    await axios.put(`/projects/${projectId}/settings`, {
      ...changes,
    })
  ).data;
};

export default projectRepository;
