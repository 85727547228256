import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import clonePage from '@converdy/c-utils/clone-page';
import generateValidSlug from '@converdy/c-utils/generate-valid-funnel-slug';

import toMutation from '../to-mutation';
import {saveElement, getResourceHydration} from '../../service';
import {
  pageResourceLoaded,
  pageCreated,
  activeDocumentChanged,
  pageSorted,
  pageDeleted,
  funnelSaved,
  funnelDeployed,
  pageContentSwapped,
} from '../action-creators';
import arrayMove from '@converdy/utils/array-move';
import arrayRemove from '@converdy/utils/array-remove';
import arrayRemoveIndex from '@converdy/utils/array-remove-index';
import generateId from '@converdy/c-utils/generate-id';
import unset from 'lodash/set';

import {
  PAGE_INSERTED,
  PAGE_CLONED,
  PAGE_CREATED,
  PAGES_LOADED,
  PAGE_SORTED,
  PAGE_RESOURCE_LOADED,
  PAGE_SETTINGS_UPDATED,
  PAGE_DELETED,
  SPLIT_TEST_CREATED,
  SPLIT_TEST_STOPPED,
  SPLIT_TEST_UPDATED,
  SPLIT_TEST_STARTED,
  PAGE_CONTENT_SWAPPED,
  PAGE_PROPERTY_CHANGED,
  PAGE_PROPERTY_INPUT,
  PAGE_SAVED,
} from '../action-types';

const ADD_PAGE = 'ADD_PAGE';
const DELETE_PAGE = 'DELETE_PAGE';
const NEW_PAGE = 'NEW_PAGE';
const UPDATE_PAGE_SETTINGS = 'UPDATE_PAGE_SETTINGS';
const SORT_PAGE = 'SORT_PAGE';
const UPDATE_PAGE = 'UPDATE_PAGE';
const UPDATE_SPLIT_TEST = 'UPDATE_SPLIT_TEST';
const REMOVE_LAST_SPLIT_TEST = 'REMOVE_LAST_SPLIT_TEST';
const SWAP_PAGE_CONTENT = 'SWAP_PAGE_CONTENT';

export const initial = {
  byId: {},
  allIds: [],
};

export const state = cloneDeep(initial);

function handlePageDeleted({commit, getters, dispatch}, {payload: {pageId}}) {
  const page = getters.pageById(pageId);

  // If page has a split test active, delete variant as well
  if (page.activeTest) {
    dispatch(pageDeleted({pageId: page.activeTest.variantId}));
  }

  commit(DELETE_PAGE, pageId);
}

function handlePagesLoaded({commit}, {payload: {pages}}) {
  // Temp fix, waiting for database update / integrity
  pages = Array.isArray(pages) ? pages : Object.values(pages);

  pages.forEach((page) => {
    const {slots, ...rest} = page;

    commit('ADD_PAGE', rest);
  });
}

async function handlePageCloned({dispatch, getters}, {payload: {id}}) {
  const page = getters.pageById(id);

  const {page: clonedPage, elements: clonedElements} = clonePage(
    page,
    getters.elements,
    getters.pageSlugs,
  );

  dispatch(
    pageCreated({
      page: clonedPage,
      elements: clonedElements,
      funnelId: page.funnelId,
    }),
  );
}

async function handlePageSaved(
  {dispatch, getters},
  {payload: {id, name, slug, config}},
) {
  const page = getters.pageById(id);

  page.settings.slug = slug || page.settings.slug;

  const clonedPage = clonePage(page, getters.elements, [], page.settings.slug);

  delete clonedPage.page.funnelId;

  saveElement(
    name,
    clonedPage,
    'page',
    config,
    getters.projectId,
    getters.workspaceId,
  );
}

/* 
SPLIT TEST DOCUMENTATION 

SplitTestCreated()
* Duplicates the page for which a split test is created. 
* Based on the option 'duplicate current page' or 'start blank' the content from the duplicated page is removed
* On the duplicated page (from now called the variant) the prop "isVariantFor" is set to true
* On the page for which the split test is created a SplitTest is pushed to the page.tests array. And to the page.activeTest property
* SplitTest = {
		id
		title,
		running: false,
		variantId: clonedPage.id,
		pageId: page.id,
		startDate: false,
		endDate: false,
		distribution: 50, // distribution to variant
	}

SplitTestStarted()
* Adds SplitTest.startDate and SplitTest.running to true.
* Updates both the page.tests[activeTest] als well as page.activeTest object

SplitTestStopped()
* Adds SplitTest.startDate and SplitTest.running to true.

* If split test has been published: add endDate and set running to false.
	else if split test was never published remove all related data.
* Updates both the page.tests[activeTest] als well as page.activeTest object
* Remove isVariantFor from variant
* Put winning content on winning page
* Handle deleteLosingPage accordingly

*/

async function handleSplitTestCreated(
  {dispatch, getters, commit},
  {payload: {pageId, basedOn, title, funnelId}},
) {
  // Get page for which a sploit test is created
  const page = getters.pageById(pageId);

  // Clone page
  let {page: clonedPage, elements: clonedElements} = clonePage(
    page,
    getters.elements,
    getters.pageSlugs,
  );

  // If basedOn 'emptypage', remove content from page slots and overwrite clonedElements to an empty object
  if (basedOn !== 'currentPage') {
    clonedPage.slots = Object.keys(clonedPage.slots).reduce((obj, curr) => {
      obj[curr] = [];
      return obj;
    }, {}); // => [main, others, etc]

    clonedElements = {};
  }

  // A way to know that the new page is currently used as a variant to anotherpage
  clonedPage.isVariantFor = page.id;

  // TODO write migration that adds the key 'tests' to all pages.
  // When migration is executed this check can be removed
  if (!page.tests) {
    commit(UPDATE_PAGE, {
      id: page.id,
      prop: 'tests',
      value: [],
    });
  }

  const SplitTest = {
    id: generateId(),
    title,
    running: false,
    variantId: clonedPage.id,
    pageId: page.id,
    distribution: 50, // distribution to variant
  };

  commit(UPDATE_PAGE, {
    id: page.id,
    prop: 'tests',
    value: [...(page.tests || []), SplitTest],
  });

  commit(UPDATE_PAGE, {
    id: page.id,
    prop: 'activeTest',
    value: SplitTest,
  });

  dispatch(
    pageCreated({
      page: clonedPage,
      elements: clonedElements,
      funnelId: funnelId,
    }),
  );
}

async function handleSplitTestStarted(
  {dispatch, getters, commit},
  {payload: {pageId}},
) {
  commit(UPDATE_SPLIT_TEST, {
    pageId: pageId,
    prop: 'startDate',
    value: new Date(),
  });

  commit(UPDATE_SPLIT_TEST, {
    pageId: pageId,
    prop: 'running',
    value: true,
  });

  dispatch(funnelSaved()).then(() => {
    dispatch(funnelDeployed()).then(() => {
      return true;
    });
  });
}

async function handleSplitTestStopped(
  {dispatch, getters, commit},
  {payload: {pageId, winner, deleteLosingPage}},
) {
  const page = getters.pageById(pageId);
  const activeTest = page.activeTest;
  const variantId = page.activeTest.variantId;
  const pageIndex = getters.pagePositionById(pageId);
  const variantIndex = getters.pagePositionById(variantId);

  // Set activeTest to false
  commit(UPDATE_PAGE, {
    id: pageId,
    prop: 'activeTest',
    value: false,
  });

  /*
		If split test has been published: add endDate and set running to false.
		else if split test was never published remove all related data.
	*/

  if (activeTest.startDate) {
    // Set running to false
    commit(UPDATE_SPLIT_TEST, {
      pageId: pageId,
      prop: 'running',
      value: false,
    });

    commit(UPDATE_SPLIT_TEST, {
      pageId: pageId,
      prop: 'endDate',
      value: new Date(),
    });
  } else {
    commit(REMOVE_LAST_SPLIT_TEST, {
      pageId: pageId,
    });
  }

  // Remove isVariantFor from variant
  commit(UPDATE_PAGE, {
    id: variantId,
    prop: 'isVariantFor',
    value: false,
  });

  // Put winning content on winning page
  if (winner === 'variant') {
    dispatch(
      pageContentSwapped({
        pageOne: getters.pageById(variantId),
        pageTwo: getters.pageById(pageId),
      }),
    );

    // Sort variant to control index
    dispatch(
      pageSorted({
        oldIndex: variantIndex,
        newIndex: pageIndex,
      }),
    );

    if (!deleteLosingPage) {
      commit(UPDATE_PAGE, {
        id: variantId,
        prop: 'settings.path',
        value: page.settings.path + '-previous-control-' + generateId(),
      });

      commit(UPDATE_PAGE, {
        id: variantId,
        prop: 'settings.title',
        value: page.settings.title + ' prev variant (control)',
      });
    }
  } else if (winner === 'control' && !deleteLosingPage) {
    commit(UPDATE_PAGE, {
      id: variantId,
      prop: 'settings.path',
      value: page.settings.path + '-previous-variant-' + generateId(),
    });

    commit(UPDATE_PAGE, {
      id: variantId,
      prop: 'settings.title',
      value: page.settings.title + ' prev variant (test)',
    });

    // Sort
    dispatch(
      pageSorted({
        oldIndex: variantIndex,
        newIndex: pageIndex + 1,
      }),
    );
  }

  // Delete losing page
  if (deleteLosingPage) {
    if (winner === 'control') {
      dispatch(pageDeleted({pageId: variantId}));
    }
    if (winner === 'test') {
      dispatch(pageDeleted({pageId}));
    }
  }

  // Save funnel
  dispatch(funnelSaved()).then(() => {
    dispatch(funnelDeployed()).then(() => {
      return true;
    });
  });
}

function handlePageResourceLoaded({commit, dispatch}, {payload: {page}}) {
  const {slots, ...rest} = page;

  commit(ADD_PAGE, rest);

  dispatch(
    activeDocumentChanged({
      type: 'page',
      id: page.id,
    }),
  );
}

async function handlePageInserted(
  {dispatch, getters},
  {payload: {funnelId, title, slug, pageResourceId, lang = 'en'}},
) {
  const {
    data: {elements, page},
  } = await getResourceHydration(pageResourceId, lang);

  if (title) {
    page.settings.title = title;
  }

  page.settings.slug = generateValidSlug(
    slug || page.settings.slug,
    getters.pageSlugs,
  );

  dispatch(
    pageCreated({
      page,
      elements,
      funnelId,
    }),
  );
}

function handlePageCreated(
  {commit, dispatch},
  {payload: {page, index = false}},
) {
  commit(ADD_PAGE, {...page, index});

  // Index is currently only defined when we are replaying from undo, redo.
  if (index === false) {
    dispatch(
      activeDocumentChanged({
        type: 'page',
        id: page.id,
      }),
    );
  }
}

export const actions = {
  [PAGE_DELETED]: handlePageDeleted,

  [PAGES_LOADED]: handlePagesLoaded,

  [PAGE_CLONED]: handlePageCloned,

  [PAGE_SAVED]: handlePageSaved,

  [PAGE_RESOURCE_LOADED]: handlePageResourceLoaded,

  [PAGE_INSERTED]: handlePageInserted,

  [PAGE_SORTED]: toMutation(SORT_PAGE),

  [PAGE_CREATED]: handlePageCreated,

  [SPLIT_TEST_CREATED]: handleSplitTestCreated,

  [SPLIT_TEST_STOPPED]: handleSplitTestStopped,

  [SPLIT_TEST_STARTED]: handleSplitTestStarted,

  [SPLIT_TEST_UPDATED]: toMutation(UPDATE_SPLIT_TEST),

  [PAGE_SETTINGS_UPDATED]: toMutation(UPDATE_PAGE_SETTINGS),

  [PAGE_CONTENT_SWAPPED]: toMutation(SWAP_PAGE_CONTENT),

  [PAGE_SETTINGS_UPDATED]: toMutation(UPDATE_PAGE_SETTINGS),

  [PAGE_PROPERTY_CHANGED]: toMutation(UPDATE_PAGE),

  [PAGE_PROPERTY_INPUT]: toMutation(UPDATE_PAGE),
};

export const mutations = {
  [ADD_PAGE](state, pageObj) {
    let {index = false, ...page} = pageObj;

    // Set State.byId
    Vue.set(state.byId, page.id, page);

    // Add to allId's array, based on potential index
    index = typeof index === 'number' ? index : state.allIds.length;
    const copy = [...state.allIds];
    copy.splice(index, 0, page.id);
    state.allIds = copy;
  },

  [DELETE_PAGE](state, id) {
    Vue.delete(state.byId, id);
    state.allIds = arrayRemove(state.allIds, id);
  },

  [NEW_PAGE](state, page) {
    /* Todo discuss, are we going to normalize components under page? */
    if (page.components) {
      Vue.set(state, 'components', {...state.components, ...page.components});
      delete page.components;
    }

    // Vue.set(state.pages, page.id, page)
    Vue.set(state.byId, page.id, page);
    state.allIds.push(page.id);
  },

  [SORT_PAGE](state, {newIndex, oldIndex}) {
    state.allIds = arrayMove(state.allIds, oldIndex, newIndex);
  },

  [UPDATE_PAGE_SETTINGS](state, {id, settings}) {
    state.byId[id].settings = settings;
  },

  [SWAP_PAGE_CONTENT](state, {pageOne, pageTwo}) {
    let pageOneSlots = pageOne.slots;
    let pageTwoSlots = pageTwo.slots;
    let pageOneId = pageOne.id;
    let pageTwoId = pageTwo.id;

    Vue.set(state.byId[pageOneId], 'slots', pageTwoSlots);
    Vue.set(state.byId[pageTwoId], 'slots', pageOneSlots);
  },

  // Updates both the 'activeSplitTest and the latest item in the tests array
  [REMOVE_LAST_SPLIT_TEST](state, {pageId}) {
    const tests = state.byId[pageId].tests;

    if (state.byId[pageId].activeTest) {
      Vue.set(state.byId[pageId], 'activeTest', false);
    }

    if (tests) {
      Vue.set(
        state.byId[pageId],
        'tests',
        arrayRemoveIndex(tests, tests.length - 1),
      );
    }
  },
  [UPDATE_SPLIT_TEST](state, {pageId, prop, value}) {
    if (state.byId[pageId].activeTest) {
      Vue.set(state.byId[pageId].activeTest, prop, value);
    }

    if (state.byId[pageId].tests) {
      Vue.set(
        state.byId[pageId].tests[state.byId[pageId].tests.length - 1],
        prop,
        value,
      );
    }
  },

  [UPDATE_PAGE](state, {id, value, prop}) {
    const page = state.byId[id];
    const clone = cloneDeep(page);

    if (typeof value === 'undefined') {
      /* Careful, unset returns true / false, not the modified object */
      unset(clone, prop);
      state.byId[id] = clone;
    } else {
      state.byId[id] = set(clone, prop, value);
    }
  },
};

export const getters = {
  pageById(state, getters) {
    return function (pageId) {
      const page = state.byId[pageId];

      if (page) {
        return Object.assign({}, page, {slots: getters.slotsById(pageId)});
      }
    };
  },
  pagePositionById(state, getters) {
    return function (pageId) {
      const index = state.allIds.indexOf(pageId);
      return index > -1 ? index : false;
    };
  },

  pagesByFunnelId(state, getters) {
    return function (funnelId) {
      return state.allIds.map((id) => getters.pageById(id));
    };
  },
  // Returns all visible pages, no split test pages or archived pages
  fileredPages(state, getters) {
    return function (funnelId) {
      return state.allIds
        .map((id) => getters.pageById(id))
        .filter((page) => !page.isVariantFor);
    };
  },
  pageSlugs: (state, getters) =>
    getters.pagesByFunnelId().map((p) => p.settings.slug),
};

export default {
  state,
  actions,
  mutations,
  getters,
};
