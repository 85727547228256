import Vue from 'vue';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';
import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';
import arrayLast from '@converdy/utils/array-last';
import objectMap from '@converdy/utils/object-map';
import toObject from '@converdy/utils/to-object';
import clone from '@converdy/c-utils/clone';
import vueSetDeep from '@converdy/c-utils/vueSetDeep';
import displayNamesMap from '@converdy/c-components/indexDisplayNames.js';
import toPath from 'lodash/toPath';

const getChildIds = require('@converdy/c-utils/get-child-ids');

import {
  ELEMENT_CLONED,
  ELEMENT_CREATED,
  ELEMENT_DELETED,
  ELEMENT_PROPERTY_CHANGED,
  ELEMENT_PROPERTY_INPUT,
  ELEMENT_PROPERTY_RESET,
  ELEMENT_SAVED,
  ELEMENT_INSERTED,
  ELEMENTS_LOADED,
  PAGE_RESOURCE_LOADED,
  PAGE_DELETED,
  PAGE_CREATED,
  ELEMENT_LOADED,
  POPUP_DELETED,
  POPUP_RESOURCE_LOADED,
  PAGE_CONTENT_SWAPPED,
} from '../action-types';

import toMutation from '../to-mutation';
import {getResourceHydration, saveElement} from '../../service';
import {elementCreated} from '../action-creators';

export const initial = {};

export const state = cloneDeep(initial);

const ADD_ELEMENT = 'ADD_ELEMENT';
const DELETE_ELEMENT = 'DELETE_ELEMENT';
const UPDATE_ELEMENT_PROPERTY = 'UPDATE_ELEMENT_PROPERTY';
const UPDATE_ELEMENT = 'UPDATE_ELEMENT';
const RESET_ELEMENT_PROPERTY = 'RESET_ELEMENT_PROPERTY';

function handleElementsLoaded({commit}, {payload: {elements}}) {
  Object.values(elements)
    .map((element) => {
      const {slots, parentId, ...rest} = element;

      return rest;
    })
    .forEach((element) => commit(ADD_ELEMENT, element));
}

async function handleElementInserted(
  {dispatch},
  {payload: {parentId, slotName, index, elementResourceId, lang = 'en'}},
) {
  try {
    const {
      data: {rootIds, elements},
    } = await getResourceHydration(elementResourceId, lang);
    const [rootId] = rootIds;
    const {[rootId]: element} = elements;

    element.parentId = parentId;

    dispatch(
      elementCreated({
        index,
        elements: elements,
        childId: rootId,
        parentId: parentId,
        slotName: slotName,
      }),
    );
  } catch (error) {
    console.error(error);
  }
}

async function handleElementCloned({dispatch, getters}, {payload: {id}}) {
  const pathToSlot = getters.pathToSlot(id);

  const index = arrayLast(getters.pathToSlotPosition(id));

  const {rootIds, elements} = clone([id], getters.elements);

  const [rootId] = rootIds;

  dispatch(
    elementCreated({
      index: index + 1,
      elements,
      childId: rootId,
      parentId: elements[rootId].parentId,
      slotName: pathToSlot.join('.'),
    }),
  );
}

function handleElementCreated({commit}, {payload: {elements}}) {
  Object.values(elements).forEach((element) => {
    commit(ADD_ELEMENT, element);
  });
}

function handlePageResourceLoaded({commit}, {payload: {elements}}) {
  elements.forEach((element) => commit(ADD_ELEMENT, element));
}

function handlePageContentSwapped({commit}, {payload: {pageOne, pageTwo}}) {
  Object.values(pageOne.slots)
    .flat(1)
    .forEach((sectionId) => {
      commit(UPDATE_ELEMENT, {
        id: sectionId,
        propName: 'parentId',
        value: pageTwo.id,
      });
    });

  Object.values(pageTwo.slots)
    .flat(1)
    .forEach((sectionId) => {
      commit(UPDATE_ELEMENT, {
        id: sectionId,
        propName: 'parentId',
        value: pageOne.id,
      });
    });
}

function handlePageDeleted({commit, getters}, {payload: {pageId}}) {
  const childIds = getters.childIdsById(pageId);

  childIds.forEach((childId) => {
    commit(DELETE_ELEMENT, {id: childId});
  });
}

function handlePopupResourceLoaded({commit}, {payload: {elements}}) {
  elements.forEach((element) => commit(ADD_ELEMENT, element));
}

function handlePopupDeleted({commit, getters}, {payload: {popupId}}) {
  const childIds = getters.childIdsById(popupId);

  childIds.forEach((childId) => {
    commit(DELETE_ELEMENT, {id: childId});
  });
}

function handlePageCreated({commit}, {payload: {elements}}) {
  Object.values(elements).forEach((element) => commit(ADD_ELEMENT, element));
}

function handleElementLoaded({commit}, {payload: {element}}) {
  commit(ADD_ELEMENT, element);
}

function handleElementDeleted({commit, getters}, {payload: {id}}) {
  const {elements} = getters.elementNormalization(id);

  Object.keys(elements).forEach((id) => {
    commit(DELETE_ELEMENT, {id});
  });
}

async function handleElementSaved({getters}, {payload: {id, name, config}}) {
  const element = getters.elementById(id);

  const type = element.name === 'CSection' ? 'section' : 'element';

  const {rootIds, elements} = getters.elementNormalization(id);

  const cloned = clone(rootIds, elements);

  saveElement(
    name,
    cloned,
    type,
    config,
    getters.projectId,
    getters.workspaceId,
  );
}

export const actions = {
  [ELEMENTS_LOADED]: handleElementsLoaded,

  [ELEMENT_INSERTED]: handleElementInserted,

  [ELEMENT_LOADED]: handleElementLoaded,

  [ELEMENT_CLONED]: handleElementCloned,

  [ELEMENT_CREATED]: handleElementCreated,

  [ELEMENT_DELETED]: handleElementDeleted,

  [ELEMENT_PROPERTY_INPUT]: toMutation(UPDATE_ELEMENT_PROPERTY),

  [ELEMENT_PROPERTY_CHANGED]: toMutation(UPDATE_ELEMENT_PROPERTY),

  [ELEMENT_PROPERTY_RESET]: toMutation(RESET_ELEMENT_PROPERTY),

  [ELEMENT_SAVED]: handleElementSaved,

  [PAGE_RESOURCE_LOADED]: handlePageResourceLoaded,

  [PAGE_CONTENT_SWAPPED]: handlePageContentSwapped,

  [PAGE_DELETED]: handlePageDeleted,

  [PAGE_CREATED]: handlePageCreated,

  [POPUP_DELETED]: handlePopupDeleted,

  [POPUP_RESOURCE_LOADED]: handlePopupResourceLoaded,
};

function addElement(state, element) {
  const {id} = element;

  Vue.set(state, id, element);
}

function deleteElement(state, payload) {
  const {id} = payload;

  Vue.delete(state, id);
}

function updateElementProperty(state, payload) {
  let {id, propName, value} = payload;

  propName = toPath(propName).join('.');

  const {[id]: element} = state;

  vueSetDeep(Vue, element, 'properties.' + propName, cloneDeep(value));
}

function updateElement(state, payload) {
  const {id, propName, value} = payload;

  /* reactivity is hard */
  Vue.set(state[id], propName, value);
}

function resetElementProperty(state, payload) {
  const {id, propName} = payload;

  const {[id]: element} = state;

  const clone = cloneDeep(element.properties || {});

  unset(clone, propName);

  Vue.set(element, 'properties', clone);
}

export const mutations = {
  [ADD_ELEMENT]: addElement,

  [DELETE_ELEMENT]: deleteElement,

  [UPDATE_ELEMENT_PROPERTY]: updateElementProperty,

  [UPDATE_ELEMENT]: updateElement,

  [RESET_ELEMENT_PROPERTY]: resetElementProperty,
};

export const getters = {
  elements: (elements, getters) => {
    return objectMap((element) => getters.elementById(element.id))(elements);
  },
  elementsByFunnelId: (elements, getters) => (funnelId) => {
    const pages = getters
      .pagesByFunnelId(funnelId)
      .map((page) => page.id)
      .map(getters.elementsByPageId)
      .flat(1);

    const popups = getters
      .popupsByFunnelId(funnelId)
      .map((page) => page.id)
      .map(getters.elementsByPageId)
      .flat(1);

    const aggregation = [...pages, ...popups];

    return aggregation;
  },

  elementsByPageId: (elements, getters) => (pageId) => {
    const slots = getters.slotsById(pageId);

    return (
      Object.values(slots)
        /* [ [ idOne, idTwo ], [ idThree ] ] => [ idOne, idTwo, idThree ] */
        .flat(1)
        .map((elementId) =>
          Object.values(getters.elementNormalization(elementId).elements),
        )
        /* [ [ elementOne ], [ elementTwo ] ] => [ elementOne, elementTwo ] */
        .flat(1)
    );
  },

  allElements: (elements) => elements,

  justElementById: (elements, getters) => (elementId) => {
    return elements[elementId];
    return Object.assign({}, elements[elementId], {
      slots: getters.slotsById(elementId),
      parentId: getters.nestingById(elementId).parentId,
    });
  },
  elementById: (elements, getters) => (elementId) => {
    return Object.assign({}, elements[elementId], {
      slots: getters.slotsById(elementId),
      parentId: getters.nestingById(elementId).parentId,
    });
  },

  parentByElementId: (elements, getters) => (elementId) => {
    const {parentId} = getters.nestingById(elementId);

    const element = getters.elementById(elementId);

    /* Todo change when pages are in nesting */
    const {name} = element;

    const parent =
      name === 'CSection'
        ? getters.pageById(parentId)
        : getters.elementById(elementId);

    return parent;
  },

  elementNormalization: (elements, getters) => (elementId) => {
    const element = getters.elementById(elementId);

    let normalizedElements = {[elementId]: element};

    const childIds = getChildIds(elementId, getters.elements);

    if (childIds) {
      const normalizedChildren = toObject(
        (child) => child.id,
        (child) => child,
      )(childIds.map((childId) => getters.elementById(childId)));

      normalizedElements = Object.assign(
        normalizedElements,
        normalizedChildren,
      );
    }

    return {
      rootIds: [elementId],
      elements: normalizedElements,
    };
  },

  closestSectionById: (state, getters) => (id) => {
    const element = getters.elementById(id);
    function closestSection(maybeSection) {
      console.log(maybeSection);
      return maybeSection.name === 'CSection'
        ? maybeSection
        : closestSection(getters.elementById(maybeSection.parentId));
    }

    return closestSection(element);
  },

  getDisplayNameFromTagName: () => (tagName) => {
    return displayNamesMap[tagName];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
