/* please keep alphabetical */

/*
 * sometimes ui-state from the controls is linked to certain
 * properties being interacted with e.g. somebody changing
 * properties visible in a popup
 *
 * in order to make programmatic (as opposed to manual) changes
 * to these properties apparent (e.g. undo-redo)
 * we need to make sure that the controls for said properties are
 * visible at the time of their updating
 *
 * therefore, track control-ui-state in the store and include as
 * non-checkpoint moments in undo-redo
 */
export const DIALOG = 'DIALOG';
export const CONTROL_UI_CHANGED = 'CONTROL_UI_CHANGED';
export const DEVICE_TYPE_CHANGED = 'DEVICE_TYPE_CHANGED';
export const EDITOR_LOADED = 'EDITOR_LOADED';
export const EDITOR_MOUNTED = 'EDITOR_MOUNTED';
export const EDITOR_UNMOUNTED = 'EDITOR_UNMOUNTED';
export const ELEMENTS_LOADED = 'ELEMENTS_LOADED';
export const ELEMENT_CLICKED = 'ELEMENT_CLICKED';
export const ELEMENT_CLONED = 'ELEMENT_CLONED';
export const ELEMENT_CREATED = 'ELEMENT_CREATED';
export const ELEMENT_DELETED = 'ELEMENT_DELETED';
export const ELEMENT_INSERTED = 'ELEMENT_INSERTED';
export const ELEMENT_INSERTION_DIALOG_OPENED =
  'ELEMENT_INSERTION_DIALOG_OPENED';
export const ELEMENT_LOADED = 'ELEMENT_LOADED';
export const ELEMENT_MOVED_DOWN = 'ELEMENT_MOVED_DOWN';
export const ELEMENT_MOVED_UP = 'ELEMENT_MOVED_UP';
export const ELEMENT_POSITION_CHANGED = 'ELEMENT_POSITION_CHANGED';
export const ELEMENT_PROPERTY_CHANGED = 'ELEMENT_PROPERTY_CHANGED';
export const ELEMENT_PROPERTY_INPUT = 'ELEMENT_PROPERTY_INPUT';
export const ELEMENT_PROPERTY_RESET = 'ELEMENT_PROPERTY_RESET';
export const ELEMENT_SAVED = 'ELEMENT_SAVED';
export const ELEMENT_SORT_ENDED = 'ELEMENT_SORT_ENDED';
export const ELEMENT_SORT_STARTED = 'ELEMENT_SORT_STARTED';
export const FUNNEL_CUSTOM_DOMAIN_UPDATED = 'FUNNEL_CUSTOM_DOMAIN_UPDATED';
export const FUNNEL_DEPLOYED = 'FUNNEL_DEPLOYED';
export const FUNNEL_LOADED = 'FUNNEL_LOADED';
export const FUNNEL_PROPERTY_CHANGED = 'FUNNEL_PROPERTY_CHANGED';
export const FUNNEL_PROPERTY_INPUT = 'FUNNEL_PROPERTY_INPUT';
export const THEME_PROPERTY_CHANGED = 'THEME_PROPERTY_CHANGED';
export const THEME_PROPERTY_INPUT = 'THEME_PROPERTY_INPUT';
export const FUNNEL_SAVED = 'FUNNEL_SAVED';
export const PAGES_LOADED = 'PAGES_LOADED';
export const PAGE_CLONED = 'PAGE_CLONED';
export const PAGE_CREATED = 'PAGE_CREATED';
export const PAGE_DELETED = 'PAGE_DELETED';
export const PAGE_INSERTED = 'PAGE_INSERTED';
export const PAGE_SORTED = 'PAGE_SORTED';
export const PAGE_LOADED = 'PAGE_LOADED';
export const PAGE_RESOURCE_LOADED = 'PAGE_RESOURCE_LOADED';
export const PAGE_PROPERTY_CHANGED = 'PAGE_PROPERTY_CHANGED';
export const PAGE_PROPERTY_INPUT = 'PAGE_PROPERTY_INPUT';
export const PAGE_SETTINGS_UPDATED = 'PAGE_SETTINGS_UPDATED';
export const PAGE_SAVED = 'PAGE_SAVED';
export const POPUP_RESOURCE_INSERTED = 'POPUP_RESOURCE_INSERTED';
export const POPUPS_LOADED = 'POPUPS_LOADED';
export const POPUP_SORTED = 'POPUP_SORTED';
export const POPUP_RESOURCE_LOADED = 'POPUP_RESOURCE_LOADED';
export const POPUP_SETTINGS_UPDATED = 'POPUP_SETTINGS_UPDATED';
export const POPUP_DELETED = 'POPUP_DELETED';
export const UNDOREDO_ACTIVE_STACK_CHANGED = 'UNDOREDO_ACTIVE_STACK_CHANGED';
export const UNDOREDO_CHECKPOINT_RESTORED = 'UNDOREDO_CHECKPOINT_RESTORED';
export const UNDOREDO_REDO = 'UNDOREDO_REDO';
export const UNDOREDO_UNDO = 'UNDOREDO_UNDO';
export const UNSELECT_ELEMENTS = 'UNSELECT_ELEMENTS';
export const USER_LOADED = 'user/USER_LOADED';
export const HIGHLIGHTED_NODES = 'HIGHLIGHTED_NODES';
export const ACTIVE_DOCUMENT_CHANGED = 'ACTIVE_DOCUMENT_CHANGED';
export const CHANGE_ACTIVE_DOCUMENT = 'CHANGE_ACTIVE_DOCUMENT';
export const SPLIT_TEST_CREATED = 'SPLIT_TEST_CREATED';
export const SPLIT_TEST_UPDATED = 'SPLIT_TEST_UPDATED';
export const SPLIT_TEST_STOPPED = 'SPLIT_TEST_STOPPED';
export const SPLIT_TEST_STARTED = 'SPLIT_TEST_STARTED';
export const SIDEBAR_VIEW_CHANGED = 'SIDEBAR_VIEW_CHANGED';
export const HEADER_CLICKED = 'HEADER_CLICKED';
export const PAGE_CONTENT_SWAPPED = 'PAGE_CONTENT_SWAPPED';
