import axios from 'axios';
import router from '../../router';
const SET_FETCHING = 'SET_FETCHING';
const SET_WORKSPACE_BY_ID = 'SET_WORKSPACE_BY_ID';
const SET_WORKSPACES = 'SET_WORKSPACES';

export const state = {
  fetching: false,
  workspaces: [],
};

export const getters = {
  workspaceId(state, getters, rootState) {
    return rootState.route.params.workspace_id;
  },
  currentWorkspace(state, getters, rootState) {
    return (
      state.workspaces.find((space) => space._id === getters.workspaceId) || {}
    );
  },
};

export const mutations = {
  [SET_WORKSPACE_BY_ID](state, id) {
    router.push({
      name: 'workspace',
      params: {
        workspace_id: id,
      },
    });

    console.log('Handle trough router...');
  },

  [SET_FETCHING](state, status) {
    state.fetching = status;
  },

  [SET_WORKSPACES](state, workspaces) {
    state.workspaces = workspaces;
  },
};

export const actions = {
  async loadWorkspaces({commit}) {
    commit(SET_FETCHING, true);

    try {
      const workspaces = await axios.get('/workspaces');

      if (workspaces.status === 200) {
        commit(SET_WORKSPACES, workspaces.data);
      }
    } catch (err) {
      console.error(err);
    }

    commit(SET_FETCHING, false);
  },
  async createWorkspace({commit, dispatch}, {payload}) {
    if (!payload.name) {
      throw new Error('Workspace needs to have a name'); // and this should be in a workspace service, like projects :)
      return;
    }

    commit(SET_FETCHING, true);

    try {
      const workspace = await axios.post(`/workspaces`, {
        name: payload.name,
      });

      if (workspace.status === 200) {
        if (workspace.data.error) {
          return;
        }
        await dispatch({type: 'loadWorkspaces'});

        commit(SET_FETCHING, false);
      }
    } catch (err) {
      console.error(err);
    }
  },
  async updateWorkspace({commit, dispatch}, {payload}) {
    const {id, options} = payload;

    commit(SET_FETCHING, true);

    try {
      const workspace = await axios.put(`/workspaces/${id}`, options);

      if (workspace.status === 200) {
        if (workspace.data.error) {
          return;
        }

        await dispatch({type: 'loadWorkspaces'});

        commit(SET_FETCHING, false);
      }
    } catch (err) {
      console.error(err);
    }

    commit(SET_FETCHING, false);
  },
  async deleteWorkspace({commit, dispatch}, {payload}) {
    const {id} = payload;
    commit(SET_FETCHING, true);

    try {
      const workspace = await axios.delete(`/workspaces/${id}`);

      if (workspace.status === 200) {
        if (workspace.data.error) {
          return;
        }

        await dispatch({type: 'loadWorkspaces'});

        commit(SET_FETCHING, false);
      }
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        window.alert(error.response.data.message);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    }

    commit(SET_FETCHING, false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
