// Maps a subset of the vuex actions to the tracking bus.
// Installed as a VUEX plugin in ./store.js
import TrackingBus from './tracking-bus';

// Automatically send (filtered) actions to segment
const mappedActions = {
  DEVICE_TYPE_CHANGED: '',
  ELEMENT_CLICKED: '',
  ELEMENT_CLONED: '',
  ELEMENT_CREATED: '',
  ELEMENT_DELETED: '',
  ELEMENT_MOVED_DOWN: '',
  ELEMENT_MOVED_UP: '',
  ELEMENT_POSITION_CHANGED: '',
  ELEMENT_PROPERTY_CHANGED: '',
  ELEMENT_PROPERTY_RESET: '',
  ELEMENT_SAVED: '',
  ELEMENT_SORT_ENDED: 'ELEMENT_SORTED',
  FUNNEL_CUSTOM_DOMAIN_UPDATED: '',
  FUNNEL_DEPLOYED: '',
  FUNNEL_LOADED: '',
  FUNNEL_PROPERTY_CHANGED: '',
  THEME_PROPERTY_CHANGED: '',
  FUNNEL_SAVED: '',
  PAGE_CLONED: '',
  PAGE_CREATED: '',
  PAGE_DELETED: '',
  PAGE_INSERTED: '',
  PAGE_PROPERTY_CHANGED: '',
  PAGE_SETTINGS_UPDATED: '',
  POPUP_RESOURCE_INSERTED: '',
  POPUP_SETTINGS_UPDATED: '',
  POPUP_DELETED: '',
  SPLIT_TEST_CREATED: '',
  SPLIT_TEST_STOPPED: '',
  SPLIT_TEST_STARTED: '',
};

const mappedActionsKeys = Object.keys(mappedActions);

export default function (store) {
  // Actions
  store.subscribeAction(function after({payload: {type, payload}}, state) {
    if (mappedActionsKeys.includes(type)) {
      const potentiallyTransformedType = mappedActionsKeys[type] || type;
      TrackingBus.$emit('track', [potentiallyTransformedType, payload]);
    }

    if (type === 'user/USER_LOADED') {
      const {_id: payloadId} = payload.user.data;
      const {_id} = state.user.currentUser;

      if (payloadId != _id) {
        const traits = (({
          intercom_user_hash,
          billing,
          role,
          is_ltd,
          is_subscriber,
          trial,
          active,
          first_name,
          last_name,
          email,
          created_at,
          print_user_id,
          chargebee_subscription,
        }) => ({
          intercom_user_hash,
          billing,
          role,
          is_ltd,
          is_subscriber,
          trial,
          active,
          first_name,
          last_name,
          email,
          created_at,
          print_user_id,
          chargebee_subscription,
        }))(payload.user.data);

        try {
          traits.chargebee_plan =
            payload.user.data.chargebee_subscription.subscription_items[0].item_price_id;
        } catch (e) {
          console.log(e);
        }

        TrackingBus.$emit('track', [
          'USER_LOGIN',
          {userId: payloadId, ...traits},
        ]);
      }
    }
  });
}
