import axios from 'axios'

export default class {

  constructor(resourceName) {
    this.resourceName = resourceName
  }

  async get(params) {
    return (await axios.get(`/${this.resourceName}`, {
      params
    })).data
  }

  async getById(id, params) {
    return (await axios.get(`/${this.resourceName}/${id}`, {
      params
    })).data
  }

  async create(body) {
    return (await axios.post(`/${this.resourceName}`, {
      ...body
    })).data
  }

  async update(id, changes) {
    return (await axios.put(`/${this.resourceName}/${id}`, {
      ...changes
    })).data
  }

  async delete(id) {
    return (await axios.delete(`/${this.resourceName}/${id}`))
  }

}