import axios from 'axios';
import axiosCache from '@converdy/utils/axios-cache';
import clone from '@converdy/c-utils/clone';
import clonePage from '@converdy/c-utils/clone-page';
import generateId from '@converdy/c-utils/generate-id';
import generateValidSlug from '@converdy/c-utils/generate-valid-funnel-slug';

window.__ADMIN__ = process.env.VUE_APP_ADMIN_BASE_URL;
window.__GENERATOR__ = process.env.VUE_APP_GENERATOR_BASE_URL;
window.__CONVERDY_DOMAIN__ = process.env.VUE_APP_CONVERDY_DOMAIN;

export function getFunnel(projectId, funnelId) {
  return axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/projects/${projectId}/funnels/${funnelId}/core`,
  );
}

export function getFunnelPages(projectId, funnelId) {
  return axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/projects/${projectId}/funnels/${funnelId}/pages`,
  );
}

export function getFunnelPopups(projectId, funnelId) {
  return axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/projects/${projectId}/funnels/${funnelId}/popups`,
  );
}

export function getFunnelElements(projectId, funnelId) {
  return axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/projects/${projectId}/funnels/${funnelId}/elements`,
  );
}

export function getResources(params) {
  return axiosCache({
    method: 'GET',
    url: `${process.env.VUE_APP_API_BASE_URL}/resources`,
    params: params,
  });
}

export function getResourceCategories(params) {
  return axios.get(`${process.env.VUE_APP_API_BASE_URL}/resources/categories`, {
    params,
  });
}

export async function getResourceHydration(resourceId, lang = 'en') {
  const response = await axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/resources/${resourceId}/hydration?lang=${lang}`,
  );

  /* 
    Replace the ids of the resource
    We might want to move this to the server 
  */

  const {
    data: {type, elements: originalElements, page: orginalPage},
  } = response;

  if (type === 'page') {
    const {page, elements} = clonePage(
      orginalPage,
      originalElements,
      [],
      orginalPage.settings.slug,
    );

    Object.assign(response.data, {
      page,
      elements,
    });
  }

  if (type === 'section' || type === 'element') {
    const {rootIds, elements} = clone(
      response.data.rootIds,
      response.data.elements,
    );

    Object.assign(response.data, {
      rootIds,
      elements,
    });
  }

  return response;
}

export async function getElementResource(resourceId) {
  const response = await axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/resources/${resourceId}`,
  );

  Object.assign(response.data, {element: response.data.elementResource});

  return response;
}

export async function saveElement(
  name,
  normalization,
  type,
  config,
  projectId,
  workspaceId,
) {
  return await axios.post(`${process.env.VUE_APP_API_BASE_URL}/resources`, {
    name,
    data: normalization,
    type,
    config,
    project_id: projectId,
    workspace_id: workspaceId,
  });
}

export async function getPageResource(pageResourceId) {
  const response = await axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/resources/pages/${pageResourceId}`,
  );

  // Todo replace ids for entire page
  // (for now this service returns only the 'empty-page' since we don't have any page templates yet)
  response.data.page.id = generateId();
  return response;
}

export async function getPopupResource(resourceId) {
  const response = await axios.get(
    `${process.env.VUE_APP_API_BASE_URL}/resources/popups/${resourceId}`,
  );

  // Todo replace ids for entire page
  // (for now this service returns only the 'empty-page' since we don't have any page templates yet)
  response.data.resource.id = generateId();
  return response;
}

export function saveFunnel(projectId, funnelId, funnel) {
  return axios.put(
    `${process.env.VUE_APP_API_BASE_URL}/projects/${projectId}/funnels/${funnelId}`,
    funnel,
  );
}

export async function publishFunnel(projectId, funnelId, funnel) {
  await axios.put(
    `${process.env.VUE_APP_API_BASE_URL}/projects/${projectId}/funnels/${funnelId}/publish`,
    funnel,
  );
}
