const log = process.env.VUE_APP_ENVIRONMENT != 'production';
console.log('logger', process.env.VUE_APP_ENVIRONMENT);
const yellow = '#FFDC00';
const blue = '#0074D9';
const lime = '#01FF70';

/*
 * unfortunately, the after & before hooks for
 * action-subscriptions don't work synchronously
 * which prevents / make it difficult to make
 * a proper implementation of an action logger that
 * groups mutations by the action that triggered them
 * which would've been the initial intention on this
 * however, altho wip, this is currently limited to
 * logging just the action
 */
export default function (store) {
  store.subscribeAction(function before({payload: action}) {
    if (log) {
      console.log(`%c${action.type}`, `color: ${yellow}`, action);
    }
  });
}
