/* 
Move unloaded event from routes.js to here. 
Also the confirm warning?

*/

import debounce from 'lodash/debounce';
import {funnelSaved} from './action-creators';

const setUnsavedFalse = ['EDITOR_LOADED', 'FUNNEL_SAVED'];
const setUnsavedTrue = [
  'ELEMENT_CLONED',
  'ELEMENT_CREATED',
  'ELEMENT_DELETED',
  'ELEMENT_INSERTED',
  'ELEMENT_LOADED',
  'ELEMENT_MOVED_DOWN',
  'ELEMENT_MOVED_UP',
  'ELEMENT_POSITION_CHANGED',
  'ELEMENT_PROPERTY_CHANGED',
  'ELEMENT_PROPERTY_INPUT',
  'ELEMENT_PROPERTY_RESET',
  'FUNNEL_CUSTOM_DOMAIN_UPDATED',
  'FUNNEL_PROPERTY_CHANGED',
  'FUNNEL_PROPERTY_INPUT',
  'THEME_PROPERTY_CHANGED',
  'THEME_PROPERTY_INPUT',
  'PAGE_CLONED',
  'PAGE_CREATED',
  'PAGE_DELETED',
  'PAGE_INSERTED',
  'PAGE_SORTED',
  'PAGE_LOADED',
  'PAGE_RESOURCE_LOADED',
  'PAGE_PROPERTY_CHANGED',
  'PAGE_PROPERTY_INPUT',
  'PAGE_SETTINGS_UPDATED',
  'POPUP_RESOURCE_INSERTED',
  'POPUPS_LOADED',
  'POPUP_SORTED',
  'POPUP_RESOURCE_LOADED',
  'POPUP_SETTINGS_UPDATED',
  'POPUP_DELETED',
  'SPLIT_TEST_CREATED',
  'SPLIT_TEST_UPDATED',
  'SPLIT_TEST_STOPPED',
  'SPLIT_TEST_STARTED',
];

export default function (store) {
  // Comment in to enable auto save
  // const save = debounce(() => {
  //   store.dispatch(funnelSaved())
  // }, 2000)

  store.subscribeAction(function before({payload: action}) {
    const type = action.type;
    // Set unsaved
    if (setUnsavedFalse.includes(type)) {
      store.commit('UNSAVED_CHANGES', false);
      window.removeEventListener('beforeunload', beforeUnload);
    } else if (setUnsavedTrue.includes(type)) {
      store.commit('UNSAVED_CHANGES', true);
      window.addEventListener('beforeunload', beforeUnload);

      if (typeof save === 'function') {
        save();
      }
    }
  });
}

function beforeUnload(e) {
  e.preventDefault();
  e.returnValue = '';
}
