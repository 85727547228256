<template>
  <div>
    <slot
      v-bind="{
        userRole,
        userFullName,
        userController,
        userPlan,
        userPlanInterval,
        userTrialStatus,
        userTrialPeriod,
        userTrialDay,
        userTrialStarted,
        userTrialUpgraded,
        userTrialDaysLeft,
        user,
        userFullName,
        userController,
        betaFeatures,
        isLtd,
        isPrintUser,
        hasActiveCbSubscription,
        cbSubscription,
        cbInstance,
        openCheckout,
        openPortal,
        pageviewsLeft,
      }"
    />
  </div>
</template>

<script>
import {getCookie} from '@converdy/utils/cookie';
import axios from 'axios';
import db from '@converdy/firebase/db';
import moment from 'moment';

export default {
  data() {
    return {
      cbInstance: false,
      pageviews: 0,
      unsubscribe: undefined,
    };
  },

  methods: {
    openCheckout(mergeData, redirectUrl) {
      const showSuccess = async () => {
        const confirmed = await this.$alert({
          type: 'success',
          title: 'Payment successful 🎉',
          message: 'Welcome to Converdy! Click below to get started.',
          closeOnConfirm: true,
          closeOnReject: false,
          cancelButtonText: '',
          confirmButtonText: 'Get Started',
        });
        console.log(confirmed, 'confirm');
        if (confirmed) {
          window.location.replace('/');
        }
      };

      this.cbInstance.openCheckout({
        hostedPage: () => {
          // Hit your end point that returns hosted page object as response
          // This sample end point will call the below api
          // https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_new_subscription
          // If you want to use paypal, go cardless and plaid, pass embed parameter as false
          var data = {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            email: this.user.email,
            company: this.user.company,
            ...mergeData,
          };

          return axios
            .post('public/chargebee/generate_checkout_new_url', data)
            .then((response) => response.data);
        },
        loaded: () => {},
        close: () => {},
        success: (hostedPageId) => {
          if (redirectUrl) {
            window.location.replace(redirectUrl);
          } else {
            showSuccess();
          }
        },
        step: (value) => {
          if (value == 'thankyou_screen') {
            if (this.redirectUrl) {
              window.location.replace(this.redirectUrl);
            } else {
              showSuccess();
            }
          }
        },
        error: (error) => {
          this.$alert({
            type: 'error',
            title: 'Error',
            message: error,
            closeOnConfirm: true,
            closeOnReject: true,
            cancelButtonText: 'Try Again',
          });
        },
      });
    },
    async openPortal(plan, redirectUrl) {
      let portalSession = false;
      try {
        this.cbInstance.setPortalSession(function () {
          return axios
            .post('/public/chargebee/generate_portal_session')
            .then((response) => response.data);
        });

        // open
        let cbPortal = this.cbInstance.createChargebeePortal();
        cbPortal.open({
          close() {
            //close callbacks
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    beautifyFromPlanId(input) {
      const words = input.split('-');

      const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });

      // Remove currency code (e.g., 'EUR') from the capitalizedWords array
      const isCurrencyCode = (word) =>
        word.length === 3 && word.toUpperCase() === word;
      const filteredWords = capitalizedWords.filter(
        (word) => !isCurrencyCode(word),
      );

      return filteredWords.join(' ');
    },
  },

  mounted() {
    this.cbInstance = window.Chargebee.init({
      site: process.env.VUE_APP_CHARGEBEE_SITE,
    });
    console.log(process.env.VUE_APP_CHARGEBEE_SITE);
  },

  created() {
    setTimeout(() => {
      this.unsubscribe = db
        .doc(
          `users/${this.user._id}/months/${moment()
            .utc()
            .startOf(`month`)
            .format('x')}`,
        )
        .onSnapshot((doc) => {
          this.pageviews =
            doc && doc.data() && doc.data().pageViews
              ? doc.data().pageViews
              : 0;
        });
    }, 2000);
  },
  reactiveProvide: {
    name: 'currentUser',
    include: [
      'userPlan',
      'userPlanInterval',
      'userTrialStatus',
      'userTrialPeriod',
      'userTrialDay',
      'userTrialDaysLeft',
      'userTrialStarted',
      'userTrialUpgraded',
      'user',
      'userRole',
      'userFullName',
      'userController',
      'betaFeatures',
      'isLtd',
      'isPrintUser',
      'hasActiveCbSubscription',
      'cbSubscription',
      'cbDueInvoicesCount',
      'cbInstance',
      'openCheckout',
      'openPortal',
      'pageviews',
      'pageviewsLeft',
      'outOfPageviews',
    ],
  },

  computed: {
    user() {
      return this.$store.getters['user/currentUser'];
    },
    currentPlan() {
      try {
        const item_price_id =
          this.user.chargebee_subscription.subscription_items[0].item_price_id;
        return this.beautifyFromPlanId(item_price_id);
      } catch (e) {
        return false;
      }
    },
    // Todo implement cancelled subscriptions
    cbSubscriptionStatus() {
      try {
        return this.user.chargebee_subscription.status; // future, in_trial, active, non_renewing, paused, cancelled (see: https://apidocs.chargebee.com/docs/api/subscriptions?prod_cat_ver=2#subscription_status)
      } catch (e) {
        return 'none';
      }
    },
    cbDueInvoicesCount() {
      try {
        return this.user.chargebee_subscription.due_invoices_count; // future, in_trial, active, non_renewing, paused, cancelled (see: https://apidocs.chargebee.com/docs/api/subscriptions?prod_cat_ver=2#subscription_status)
      } catch (e) {
        return 0;
      }
    },
    hasActiveCbSubscription() {
      return Boolean(
        {
          active: true,
          future: true,
          in_trial: true,
          non_renewing: true,
        }[this.cbSubscriptionStatus], // Return true for, active, future, in_trial, non_renewing
      );
    },

    cbSubscription() {
      return this.user.chargebee_subscription;
    },

    userRole() {
      return this.user.role;
    },
    betaFeatures() {
      return this.user.betaFeatures || {};
    },
    userBilling() {
      return this.user.billing || {};
    },
    userTrial() {
      return this.user.trial || {};
    },
    isLtd() {
      return this.userPlan === 'lifetime';
    },
    isPrintUser() {
      return this.user.print_user_id;
    },
    userPlan() {
      return this.userBilling.plan;
    },
    userPlanInterval() {
      return this.userBilling.interval;
    },
    userTrialStatus() {
      return this.userTrial.status; // 'active', 'expired',
    },
    userTrialPeriod() {
      return this.userTrial.period;
    },
    userTrialDay() {
      return this.userTrial.day;
    },
    userTrialDaysLeft() {
      return this.userTrial.period - this.userTrial.day;
    },
    userTrialStarted() {
      return this.userTrial.started_at;
    },
    userTrialUpgraded() {
      return this.userTrial.upgraded_at;
    },
    userFullName() {
      return `${this.user.first_name} ${this.user.last_name}`;
    },
    userController() {
      if (this.user && getCookie('admin_take_back_auth_token'))
        return getCookie('admin_take_back_auth_token');
      else return null;
    },
    pageviewsLeft() {
      if (!this.user || !this.user.limits) return;
      if (this.user.limits.pageviews == 'unlimited') return 'unlimited';

      const totalViewsAvailable =
        Number(this.user.limits.pageviews) * 1000 +
        this.user.credits_owned -
        this.pageviews;
      return totalViewsAvailable;
    },
    outOfPageviews() {
      return typeof this.pageviewsLeft === 'number' && this.pageviewsLeft <= 0;
    },
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
  },
};

function hoursDifference(unixTimestamp, today) {
  today = today || moment().startOf('day');
  const inputDate = moment.unix(unixTimestamp).startOf('day');
  const diff = inputDate.diff(today, 'hours');
  return diff;
}
</script>
