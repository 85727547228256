var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("default",null,null,{
      userRole: _vm.userRole,
      userFullName: _vm.userFullName,
      userController: _vm.userController,
      userPlan: _vm.userPlan,
      userPlanInterval: _vm.userPlanInterval,
      userTrialStatus: _vm.userTrialStatus,
      userTrialPeriod: _vm.userTrialPeriod,
      userTrialDay: _vm.userTrialDay,
      userTrialStarted: _vm.userTrialStarted,
      userTrialUpgraded: _vm.userTrialUpgraded,
      userTrialDaysLeft: _vm.userTrialDaysLeft,
      user: _vm.user,
      userFullName: _vm.userFullName,
      userController: _vm.userController,
      betaFeatures: _vm.betaFeatures,
      isLtd: _vm.isLtd,
      isPrintUser: _vm.isPrintUser,
      hasActiveCbSubscription: _vm.hasActiveCbSubscription,
      cbSubscription: _vm.cbSubscription,
      cbInstance: _vm.cbInstance,
      openCheckout: _vm.openCheckout,
      openPortal: _vm.openPortal,
      pageviewsLeft: _vm.pageviewsLeft,
    })],2)}
var staticRenderFns = []

export { render, staticRenderFns }