const WorkspacesResolver = () =>
  import('../views/Workspaces/WorkspacesResolver');

const Workspace = () => import('../views/Workspaces/Workspace');
const WorkspaceProjects = () => import('../views/Workspaces/WorkspaceProjects');
const WorkspaceBilling = () => import('../views/Workspaces/WorkspaceBilling');
const WorkspaceBillingStatus = () =>
  import('../views/Workspaces/WorkspaceBillingStatus');
const WorkspaceSettings = () => import('../views/Workspaces/WorkspaceSettings');

const Project = () => import('../views/Project/Project');

const Plans = () => import('../views/Plans/Plans');

const Profile = () => import('../views/Profile/Profile');
const UserProfile = () => import('../views/Profile/UserProfile');
const Developer = () => import('../views/Profile/Developer');
const Billing = () => import('../views/Profile/Billing');
const Limits = () => import('../views/Profile/Limits');

const Admin = () => import('../views/Admin/Admin');

const OnboardStore = () => import('../views/OnboardStore/OnboardStore-1');
const OnboardPrint = () => import('../views/OnboardPrint/OnboardPrint');

const Onboard = () => import('../views/Onboard/Onboard');
const OnboardProject = () => import('../views/Onboard/OnboardProject');
const OnboardGoal = () => import('../views/Onboard/OnboardGoal');
const OnboardComplete = () => import('../views/Onboard/OnboardComplete');

const Editor = () => import('../views/Editor/Editor');
const EditorSidebar = () => import('../views/Editor/Sidebar');

const SignIn = () => import('../views/Lobby/SignIn/Signin');
const SignUp = () => import('../views/Lobby/SignUp/Signup');
const SignUpPrint = () => import('../views/Lobby/SignUpPrint/SignupPrint');

const ResetEmail = () => import('../views/Lobby/ResetEmail/ResetEmail');
const ResetPassword = () =>
  import('../views/Lobby/ResetPassword/ResetPassword');

const Home = () => import('../views/Project/Home/Home');

const Analytics = () => import('../views/Project/Analytics/Analytics');
const Users = () => import('../views/Project/Users/Users');
const AnalyticsDashboard = () => import('../views/Project/Analytics/Dashboard');
const AnalyticsDashboardCharts = () =>
  import('../views/Project/Analytics/Tabs/Charts');
const AnalyticsDashboardPages = () =>
  import('../views/Project/Analytics/Tabs/Pages');
const AnalyticsDashboardSplitTests = () =>
  import('../views/Project/Analytics/Tabs/SplitTests');
const AnalyticsLiveView = () => import('../views/Project/Analytics/LiveView');

const Funnels = () => import('../views/Project/Funnels/Funnels');

const FunnelNew = () => import('../views/Project/Funnels/FunnelNew');

const Forms = () => import('../views/Project/Forms/Forms.vue');
const FormDetails = () => import('../views/Project/Forms/FormDetails.vue');

const Contacts = () => import('../views/Project/Contacts/Contacts.vue');
const ContactTable = () =>
  import('../views/Project/Contacts/ContactsTable.vue');

const Contact = () =>
  import('../views/Project/Contacts/ContactDetails/Contact.vue');

const ContactActivity = () =>
  import('../views/Project/Contacts/ContactDetails/ContactActivity');
const ContactNotes = () =>
  import('../views/Project/Contacts/ContactDetails/Note/Notes');
const ContactSubscriptions = () =>
  import(
    '../views/Project/Contacts/ContactDetails/Subscriptions/ContactSubscriptions'
  );
const ContactTransactions = () =>
  import(
    '../views/Project/Contacts/ContactDetails/Transactions/ContactTransactions'
  );
const ContactOrders = () =>
  import('../views/Project/Contacts/ContactDetails/Orders/ContactOrders');

const Products = () => import('@/views/Project/Products/Products');
const ProductsTable = () => import('@/views/Project/Products/ProductsTable');
const NewProduct = () => import('@/views/Project/Products/NewProduct');
const ProductDetails = () =>
  import('@/views/Project/Products/ProductDetails/index');

const Orders = () => import('@/views/Project/Orders/Orders');
const OrdersTable = () => import('@/views/Project/Orders/OrdersTable');
const OrderDetails = () => import('@/views/Project/Orders/OrderDetails');

const Invoices = () => import('@/views/Project/Invoices/Invoices');
const InvoicesTable = () => import('@/views/Project/Invoices/InvoicesTable');
const InvoiceDetails = () =>
  import('@/views/Project/Invoices/InvoiceDetails/index');

const Subscriptions = () =>
  import('@/views/Project/Subscriptions/Subscriptions');
const SubscriptionsTable = () =>
  import('@/views/Project/Subscriptions/SubscriptionsTable');
const SubscriptionDetails = () =>
  import('@/views/Project/Subscriptions/SubscriptionDetails/index');

const Transactions = () => import('@/views/Project/Transactions/Transactions');
const TransactionsTable = () =>
  import('@/views/Project/Transactions/TransactionsTable');
const TransactionDetails = () =>
  import('@/views/Project/Transactions/TransactionDetails');

const Coupons = () => import('@/views/Project/Coupons/Coupons');
const CouponsTable = () => import('@/views/Project/Coupons/CouponsTable');
const CouponDetails = () =>
  import('@/views/Project/Coupons/CouponDetails/index');

const Affiliates = () => import('@/views/Project/Affiliates');
const AffiliateSellersTable = () =>
  import('@/views/Project/Affiliates/Sellers/SellersTable');
const AffiliateSellerDetails = () =>
  import('@/views/Project/Affiliates/Sellers/SellerDetails/index');
const AffiliateLinksTable = () =>
  import('@/views/Project/Affiliates/Links/LinksTable');
const AffiliateLinkDetails = () =>
  import('@/views/Project/Affiliates/Links/LinkDetails/index');
const AffiliateProfiles = () => import('@/views/Project/Affiliates/Profiles');
const AffiliateProfilesTable = () =>
  import('@/views/Project/Affiliates/Profiles/ProfilesTable');
const AffiliateProfileDetails = () =>
  import('@/views/Project/Affiliates/Profiles/ProfileDetails/index');

const AffiliateCommissions = () =>
  import('@/views/Project/Affiliates/Commissions');
const AffiliateCommissionsTable = () =>
  import('@/views/Project/Affiliates/Commissions/CommissionTable');
const AffiliateCommissionDetails = () =>
  import('@/views/Project/Affiliates/Commissions/CommissionDetails');
const AffiliateSettings = () =>
  import('@/views/Project/Affiliates/Settings/AffiliateSettings');

const Settings = () => import('@/views/Project/Settings/Settings');
const ProjectSettingsGeneral = () =>
  import('@/views/Project/Settings/General/General');
const projectSettingsBilling = () =>
  import('@/views/Project/Settings/Billing/Billing');
const ProjectSettingsDomains = () =>
  import('@/views/Project/Settings/Domains/Domains');
const ProjectSettingsDomainsList = () =>
  import('@/views/Project/Settings/Domains/DomainsList');
const ProjectSettingsDomainsManage = () =>
  import('@/views/Project/Settings/Domains/DomainManage/DomainManage');
const ProjectSettingsIntegrations = () =>
  import('@/views/Project/Settings/Integrations/Integrations');
const ProjectSettingsIntegrationAdd = () =>
  import('@/views/Project/Settings/Integrations/AddIntegration');
const ProjectSettingsStore = () =>
  import('@/views/Project/Settings/Store/Store');
const ProjectSettingsWebhooks = () =>
  import('@/views/Project/Settings/Webhooks/Webhooks');
const ProjectSettingsNotifications = () =>
  import('@/views/Project/Settings/Notifications/Notifications');
const ProjectSettingsNotification = () =>
  import('@/views/Project/Settings/Notifications/Notification');

const PaymentProcessorRedirect = () =>
  import('@/views/PaymentProcessorRedirect');

const ZapierOauth = () => import('@/views/ZapierOauth');

const Localization = () => import('@/views/Localization/Localization');

const routes = [
  {
    path: '/onboard',
    name: 'onboard',
    redirect: '/onboard/project',
    component: Onboard,
    children: [
      {
        path: 'project',
        name: 'onboard-project',
        component: OnboardProject,
      },
      {
        path: 'goal',
        name: 'onboard-goal',
        component: OnboardGoal,
      },
      {
        path: 'complete',
        name: 'onboard-complete',
        component: OnboardComplete,
      },
    ],
  },
  {
    path: '/workspaces',
    name: 'workspaces',
    component: WorkspacesResolver,
  },
  {
    path: '/workspaces/:workspace_id',
    name: 'workspace',
    redirect: '/workspaces/:workspace_id/projects',
    component: Workspace,
    children: [
      {
        path: 'projects',
        name: 'workspace-projects',
        component: WorkspaceProjects,
      },
      {
        path: 'billing',
        name: 'workspace-billing',
        component: WorkspaceBilling,
      },
      {
        path: 'billing',
        name: 'workspace-billing-status',
        component: WorkspaceBillingStatus,
      },
      {
        path: 'settings',
        name: 'workspace-settings',
        component: WorkspaceSettings,
      },
    ],
  },
  {
    path: '/plans',
    name: 'plans',
    component: Plans,
  },
  {
    path: '/profile',
    name: 'profile',
    redirect: '/profile/profile',
    component: Profile,
    children: [
      {
        name: 'profile/user',
        path: 'profile',
        component: UserProfile,
      },
      {
        name: 'profile/developer',
        path: 'developer',
        component: Developer,
      },
      {
        name: 'profile/limits',
        path: 'limits',
        component: Limits,
      },
      {
        name: 'profile/billing',
        path: 'billing',
        component: Billing,
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
  },
  {
    path: '/workspaces/:workspace_id/projects/:projectId',
    redirect: (to) => `${to.path}/home`,
    name: 'project',
    component: Project,
    children: [
      {
        name: 'home',
        path: 'home',
        component: Home,
      },
      {
        name: 'analytics',
        path: 'analytics',
        redirect: 'analytics/liveview',
        component: Analytics,
        children: [
          {
            path: 'liveview',
            name: 'analyticsLiveView',
            component: AnalyticsLiveView,
          },
          {
            path: 'dashboard',
            name: 'analyticsDashboard',
            redirect: 'dashboard/charts',
            component: AnalyticsDashboard,
            children: [
              {
                path: 'charts',
                name: 'analyticsCharts',
                component: AnalyticsDashboardCharts,
              },
              {
                path: 'pages',
                name: 'analyticsPages',
                component: AnalyticsDashboardPages,
              },
              {
                path: 'split-tests',
                name: 'analyticsSplitTests',
                component: AnalyticsDashboardSplitTests,
              },
            ],
          },
        ],
      },
      {
        path: 'funnels',
        name: 'funnels',
        component: Funnels,
      },
      {
        path: 'funnels/new',
        name: 'funnel_new',
        component: FunnelNew,
      },
      {
        path: 'forms',
        name: 'forms',
        component: Forms,
        children: [
          {
            path: 'details/:id',
            name: 'form_details',
            component: FormDetails,
          },
        ],
      },
      {
        path: 'contacts',
        component: Contacts,
        children: [
          {
            path: '/',
            name: 'contacts',
            component: ContactTable,
          },
          {
            path: 'details/:contactId',
            name: 'contact_details',
            component: Contact,
            children: [
              {
                path: '/',
                name: 'contact_activity',
                component: ContactActivity,
              },
              {
                path: 'notes',
                name: 'contact_notes',
                component: ContactNotes,
              },
              {
                path: 'orders',
                name: 'contact_orders',
                component: ContactOrders,
              },
              {
                path: 'subscriptions',
                name: 'contact_subscriptions',
                component: ContactSubscriptions,
              },
              {
                path: 'transactions',
                name: 'contact_transactions',
                component: ContactTransactions,
              },
            ],
          },
        ],
      },
      {
        path: 'products',
        component: Products,
        children: [
          {
            path: '/',
            name: 'products',
            component: ProductsTable,
          },
          {
            path: ':productId',
            name: 'product_details',
            component: ProductDetails,
          },
        ],
      },
      {
        path: 'orders',
        component: Orders,
        children: [
          {
            path: '/',
            name: 'orders',
            component: OrdersTable,
          },
          {
            path: ':orderId',
            name: 'order_details',
            component: OrderDetails,
          },
        ],
      },
      {
        path: 'invoices',
        component: Invoices,
        children: [
          {
            path: '/',
            name: 'invoices',
            component: InvoicesTable,
          },
          {
            path: ':invoiceId',
            name: 'invoice_details',
            component: InvoiceDetails,
          },
        ],
      },
      {
        path: 'subscriptions',
        component: Subscriptions,
        children: [
          {
            path: '/',
            name: 'subscriptions',
            component: SubscriptionsTable,
          },
          {
            path: ':subscriptionId',
            name: 'subscription_details',
            component: SubscriptionDetails,
          },
        ],
      },
      {
        path: 'transactions',
        component: Transactions,
        children: [
          {
            path: '/',
            name: 'transactions',
            component: TransactionsTable,
          },
          {
            path: ':transactionId',
            name: 'transaction_details',
            component: TransactionDetails,
          },
        ],
      },
      {
        path: 'coupons',
        component: Coupons,
        children: [
          {
            path: '/',
            name: 'coupons',
            component: CouponsTable,
          },
          {
            path: ':couponId',
            name: 'coupon_details',
            component: CouponDetails,
          },
        ],
      },
      {
        path: 'media',
        name: 'media',
      },
      {
        path: 'settings',
        component: Settings,
        children: [
          {
            path: 'general',
            name: 'projectSettingsGeneral',
            component: ProjectSettingsGeneral,
          },
          {
            path: 'billing',
            name: 'projectSettingsBilling',
            component: projectSettingsBilling,
          },
          {
            path: 'domains',
            name: 'projectSettingsDomains',
            component: ProjectSettingsDomainsList,
          },
          {
            path: 'domains/:domain_id',
            name: 'projectSettingsDomainsManage',
            component: ProjectSettingsDomainsManage,
          },
          {
            path: 'integrations',
            name: 'projectSettingsIntegrations',
            component: ProjectSettingsIntegrations,
            children: [
              {
                path: 'add',
                name: 'projectSettingsIntegrationAdd',
                component: ProjectSettingsIntegrationAdd,
              },
            ],
          },
          {
            path: 'store',
            name: 'projectSettingsStore',
            component: ProjectSettingsStore,
          },
          {
            path: 'webhooks',
            name: 'projectSettingsWebhooks',
            component: ProjectSettingsWebhooks,
          },
          {
            path: 'notifications',
            name: 'projectSettingsNotifications',
            component: ProjectSettingsNotifications,
          },
          {
            path: 'notifications/:notification_id',
            name: 'projectSettingsNotification',
            component: ProjectSettingsNotification,
          },
          {
            path: 'users',
            name: 'projectSettingsUsers',
            component: Users,
          },
        ],
      },
      {
        name: 'affiliates',
        path: 'affiliates',
        redirect: 'affiliates/sellers',
        component: Affiliates,
        children: [
          {
            path: 'sellers',
            name: 'affiliateSellers',
            component: AffiliateSellersTable,
            children: [
              {
                path: 'details/:sellerId',
                name: 'affiliateSellerDetails',
                component: AffiliateSellerDetails,
              },
            ],
          },
          {
            path: 'profiles',
            component: AffiliateProfiles,
            children: [
              {
                path: '/',
                name: 'affiliateProfiles',
                component: AffiliateProfilesTable,
              },
              {
                path: ':profileId',
                name: 'affiliateProfileDetails',
                component: AffiliateProfileDetails,
              },
            ],
          },
          {
            path: 'commissions',
            component: AffiliateCommissions,
            children: [
              {
                path: '/',
                name: 'affiliateCommissions',
                component: AffiliateCommissionsTable,
              },
              {
                path: ':commissionId',
                name: 'affiliateCommissionDetails',
                component: AffiliateCommissionDetails,
              },
            ],
          },
          {
            path: 'links',
            name: 'affiliateLinks',
            component: AffiliateLinksTable,
            children: [
              {
                path: 'details/:linkId',
                name: 'affiliateLinkDetails',
                component: AffiliateLinkDetails,
              },
            ],
          },
          {
            path: 'settings',
            name: 'affiliateSettings',
            component: AffiliateSettings,
          },
        ],
      },
    ],
  },
  {
    path: '/workspaces/:workspace_id/projects/:projectId/onboard-store',
    name: 'onboard-store',
    component: OnboardStore,
  },
  {
    path: '/workspaces/:workspace_id/projects/:projectId/onboard-print',
    name: 'onboard-print',
    component: OnboardPrint,
  },
  {
    path: '/workspaces/:workspace_id/projects/:projectId/editor',
    name: 'editor',
    component: Editor,
    children: [
      {
        path: ':funnelId/',
        name: 'overview',
        components: {
          sidebar: EditorSidebar,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: SignIn,
  },
  {
    path: '/login/:id',
    component: SignIn,
  },
  {
    path: '/register',
    name: 'register',
    component: SignUp,
  },
  {
    path: '/register-print',
    name: 'registerPrint',
    component: SignUpPrint,
  },
  {
    path: '/reset',
    name: 'reset-email',
    component: ResetEmail,
  },
  {
    path: '/reset/:token',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/paymentProcessorRedirect',
    name: 'paymentProcessorRedirect',
    component: PaymentProcessorRedirect,
  },
  {
    path: '/oauth/zapier',
    name: 'zapierOauth',
    component: ZapierOauth,
  },
  {
    path: '/localization/:resource_id',
    name: 'localization',
    component: Localization,
  },
];

export default routes;
