import cloneDeep from 'lodash/cloneDeep';

import {
  UNDOREDO_CHECKPOINT_RESTORED,
  UNDOREDO_UNDO,
  UNDOREDO_REDO,
  UNDOREDO_ACTIVE_STACK_CHANGED,
} from '../action-types';

export const SET_DONE_ACTIONS = 'SET_DONE_ACTIONS';
export const SET_UNDONE_ACTIONS = 'SET_UNDONE_ACTIONS';
export const SET_ACTIVE_STACK = 'SET_ACTIVE_STACK';

export const initial = {
  done: {},
  undone: {},
};

const state = cloneDeep(initial);

const actions = {
  [UNDOREDO_CHECKPOINT_RESTORED]({commit}, {payload: {done, undone}}) {
    commit(SET_DONE_ACTIONS, {done});
    commit(SET_UNDONE_ACTIONS, {undone});
  },

  [UNDOREDO_UNDO]() {},

  [UNDOREDO_REDO]() {},

  [UNDOREDO_ACTIVE_STACK_CHANGED]({commit}, {payload: {stack}}) {
    commit(SET_ACTIVE_STACK, {stack});
  },
};

const mutations = {
  [SET_DONE_ACTIONS](state, {done}) {
    state.done = cloneDeep(done);
  },

  [SET_UNDONE_ACTIONS](state, {undone}) {
    state.undone = cloneDeep(undone);
  },

  ['SYNC_UNDO_REDO'](state, {done, undone}) {
    state.done = cloneDeep(done);
    state.undone = cloneDeep(undone);
  },
};

const getters = {
  activeUndoRedoStack: (state, getters) => {
    const sidebarView = getters.sidebarView;
    const allowedViews = ['overview', 'selected'];

    // Page
    if (getters.activePage && allowedViews.includes(sidebarView)) {
      return getters.activePageId;
    }

    // Theme
    if (getters.activePage && ['funnelStyling'].includes(sidebarView)) {
      return 'theme';
    }
  },

  canUndo: (state, getters) => (stack) => {
    return (
      (state.done[stack] || []).filter((action) => {
        try {
          return action.meta.undoredo.checkpoint;
        } catch {
          return false;
        }
      }).length > 0
    );
  },
  canRedo: (state, getters) => (stack) => {
    return (
      (state.undone[stack] || []).filter((action) => {
        try {
          return action.meta.undoredo.checkpoint;
        } catch {
          return false;
        }
      }).length > 0
    );
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
