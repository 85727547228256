import projectRepository from '../../repository/projects';
import projectService from '../../service/project';

const SET_PROJECTS = 'SET_PROJECTS';
const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
const CLEAR_CURRENT_PROJECT = 'CLEAR_CURRENT_PROJECT';
const SET_FETCHING_PROJECT = 'SET_FETCHING_PROJECT';
const SET_FETCHING_PROJECTS = 'SET_FETCHING_PROJECTS';

export const state = {
  fetchingProject: false,
  fetchingProjects: false,
  currentProject: null,
  projects: [],
};

export const getters = {
  projectId(state, getters, rootState) {
    return rootState.route.params.projectId;
  },
  getProjectById: (state, getters) => (projectId) => {
    return state.projects.find((project) => project._id === projectId);
  },
  currentProject(state, getters, rootState) {
    return state.currentProject;
  },
  projects(state, getters, rootState) {
    return state.projects;
  },
  projectsByWorkspaceId: (state, getters, rootState) => (workspaceId) => {
    return state.projects.filter(
      (project) => project.workspace_id === workspaceId,
    );
  },
};

export const mutations = {
  [SET_CURRENT_PROJECT](state, project) {
    state.currentProject = project;
  },
  [SET_PROJECTS](state, projects) {
    state.projects = projects;
  },

  [CLEAR_CURRENT_PROJECT](state) {
    state.currentProject = null;
  },

  [SET_FETCHING_PROJECT](state, status) {
    state.fetchingProject = status;
  },
  [SET_FETCHING_PROJECTS](state, status) {
    state.fetchingProjects = status;
  },
};

export const actions = {
  async loadProject({getters, commit}) {
    commit(SET_FETCHING_PROJECT, true);
    try {
      const project = await projectRepository.getById(getters.projectId);
      commit(SET_CURRENT_PROJECT, project);
    } catch (err) {
      console.error(err);
    } finally {
      commit(SET_FETCHING_PROJECT, false);
    }
  },

  async loadProjects({getters, commit}) {
    commit(SET_FETCHING_PROJECTS, true);
    try {
      const projects = await projectRepository.get();
      commit(SET_PROJECTS, projects);
      return projects;
    } catch (err) {
      console.error(err);
    } finally {
      commit(SET_FETCHING_PROJECTS, false);
    }
  },

  async createProject({commit, dispatch}, {project_name, workspace_id}) {
    commit(SET_FETCHING_PROJECTS, true);

    try {
      // Create
      const project = await projectRepository.create({
        name: project_name,
        workspace_id,
      });

      // Update rest of store
      await dispatch({type: 'loadProjects'});

      return project;
    } catch (err) {
      console.error(err);
    } finally {
      commit(SET_FETCHING_PROJECTS, false);
    }
  },

  async setProjectById({commit, getters, dispatch}, {id}) {
    // get project
    const {workspace_id} = getters.getProjectById(id);

    // Prevent redundant navigation
    if (
      router.currentRoute.params.projectId === id &&
      router.currentRoute.params.workspace_id === workspace_id
    ) {
      return;
    }

    router.push({
      name: 'home', // Needed because we haven't made views like site tables derive state from the store
      params: {
        projectId: id,
        workspace_id: workspace_id,
      },
    });

    // Update rest of store
    await dispatch({type: 'loadProject'});
  },

  unsetProject({commit}) {
    commit(CLEAR_CURRENT_PROJECT);
  },

  async update({getters, commit}, changes) {
    const updatedProject = await projectRepository.update(
      getters.projectId,
      changes,
    );
    commit(SET_CURRENT_PROJECT, updatedProject);
  },

  async updateSettings({getters, commit}, changes) {
    const updatedProject = await projectRepository.updateSettings(
      getters.projectId,
      changes,
    );
    commit(SET_CURRENT_PROJECT, updatedProject);
  },

  async resetWebhookSecret({getters, commit}) {
    const updatedProject = await projectService.resetWebhookSecret(
      getters.projectId,
    );
    commit(SET_CURRENT_PROJECT, updatedProject);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
