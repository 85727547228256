import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store/store';

const router = new VueRouter({routes, mode: 'history'});

// Outside editor navigation guard
router.beforeEach((to, from, next) => {
  let continueRequest = true;

  if (
    from.path.includes('editor') &&
    !to.path.includes('editor') &&
    store.getters.unsavedChanges
  ) {
    if (to.params.confirmed) {
      store.commit('RESET_STATE');
      next();
      return;
    }

    continueRequest = window.confirm(
      'Sure you want to leave the editor? If you have unsaved changes those will be lost.',
    );
  }

  if (continueRequest) {
    next();
  }
});

// If linked to login, refresh to reset all scripts that load on login (GTM)
router.beforeEach((to, from, next) => {
  if (to.name == 'login' && from.name) {
    window.location.href = '/login'; // perform refresh
  } else {
    next();
  }
});

router.afterEach((to, from, next) => {
  if (from.path.includes('editor') && !to.path.includes('editor')) {
    store.commit('RESET_STATE');
  }
});

// Add on before unload event when entering the editor
router.beforeEach((to, from, next) => {
  if (
    (!from.path.includes('editor') || from.path === '/') &&
    to.path.includes('editor')
  ) {
  }
  next();
});

// Remove beforeunload event when leaving the editor
router.beforeEach((to, from, next) => {
  if (from.path.includes('editor') && !to.path.includes('editor')) {
  }
  next();
});

// Clear localstorage from our caches.
window.addEventListener('load', () => {
  const storage = window.localStorage;
  for (const storageItem in storage) {
    if (storageItem.includes(process.env.VUE_APP_API_BASE_URL)) {
      window.localStorage.removeItem(storageItem);
    }
  }
});

// window.addEventListener("payment_success", (event) => {
//   console.log('event', event)
// }, false);

// window.addEventListener("payment_error", (event) => {
//   console.log('event', event)
// }, false);

window.router = router;
export default router;
