import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import objectMap from '@converdy/utils/object-map'

import { CONTROL_UI_CHANGED } from '../action-types'

const UPDATE_CONTROL_PROPERTIES = 'UPDATE_CONTROL_PROPERTIES'

/* make sure the element keys are mirrored for controls */
export const initial = {}

export const state = cloneDeep(initial)

export const actions = {
  [ CONTROL_UI_CHANGED ] ({ rootState, commit }, { payload }) {
    commit(
      UPDATE_CONTROL_PROPERTIES,
      {
        ...payload,
        id: rootState.editor.selectedElementIds[0]
      }
    )
  }
}

export const mutations = { 
	[ UPDATE_CONTROL_PROPERTIES ] (state, { id, propName, value }) {
    Vue.set(state, id, cloneDeep(set(state[id] || {}, propName, value)))
	}
}

export const getters = {
  control: state => elementId => state[elementId] || {},
  controlByElementId: state => elementId => state[elementId]
}

export default {
  state,
  actions,
  mutations,
  getters
}
