import Vue from 'vue';
import set from 'lodash/set';
import get from 'lodash/get';
import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';

import {
  FUNNEL_LOADED,
  FUNNEL_SETTINGS_UPDATED,
  FUNNEL_THEME_UPDATED,
  FUNNEL_PROPERTY_CHANGED,
  FUNNEL_PROPERTY_INPUT,
  THEME_PROPERTY_CHANGED,
  THEME_PROPERTY_INPUT,
  FUNNEL_CUSTOM_DOMAIN_UPDATED,
} from '../action-types';

import toMutation from '../to-mutation';

const ADD_FUNNEL = 'ADD_FUNNEL';
const UPDATE_FUNNEL = 'UPDATE_FUNNEL';
const UPDATE_FUNNEL_SETTINGS = 'UPDATE_FUNNEL_SETTINGS';
const UPDATE_FUNNEL_CUSTOM_DOMAIN = 'UPDATE_FUNNEL_CUSTOM_DOMAIN';
const UPDATE_FUNNEL_THEME = 'UPDATE_FUNNEL_THEME';

export const initial = {};

export const state = cloneDeep(initial);

export const actions = {
  [FUNNEL_LOADED]: toMutation(ADD_FUNNEL),

  [FUNNEL_SETTINGS_UPDATED]: toMutation(UPDATE_FUNNEL_SETTINGS),

  [FUNNEL_THEME_UPDATED]: toMutation(UPDATE_FUNNEL_THEME),

  [FUNNEL_PROPERTY_CHANGED]: toMutation(UPDATE_FUNNEL),

  [FUNNEL_PROPERTY_INPUT]: toMutation(UPDATE_FUNNEL),

  [THEME_PROPERTY_CHANGED]: toMutation(UPDATE_FUNNEL),

  [THEME_PROPERTY_INPUT]: toMutation(UPDATE_FUNNEL),

  [FUNNEL_CUSTOM_DOMAIN_UPDATED]: toMutation(UPDATE_FUNNEL_CUSTOM_DOMAIN),
};

export const mutations = {
  [ADD_FUNNEL](state, {funnel}) {
    if (!funnel.theme) {
      Object.assign(funnel, {theme: {}});
    }

    if (!funnel.custom_domain) {
      Object.assign(funnel, {custom_domain: ''});
    }

    Vue.set(state, funnel._id, funnel);
  },

  [UPDATE_FUNNEL_SETTINGS](state, {funnelId, settings}) {
    state[funnelId].settings = settings;
  },

  [UPDATE_FUNNEL_THEME](state, {funnelId, theme}) {
    state[funnelId].theme = theme;
  },

  [UPDATE_FUNNEL_CUSTOM_DOMAIN](state, {funnelId, customDomain}) {
    Vue.set(state[funnelId], 'custom_domain', customDomain);
  },

  [UPDATE_FUNNEL_THEME](state, {funnelId, theme}) {
    state[funnelId].theme = theme;
  },

  [UPDATE_FUNNEL](state, {funnelId, value, path}) {
    const funnel = state[funnelId];
    const clone = cloneDeep(funnel);
    if (value === undefined) {
      /* Careful, unset returns true / false, not the modified object */
      unset(clone, path);
      state[funnelId] = clone;
    } else {
      state[funnelId] = set(clone, path, value);
    }
  },
};

export const getters = {
  funnelById(funnels) {
    return function (funnelId) {
      return funnels[funnelId];
    };
  },
  // Todo get rid of the concept where we allow 'multiple' funnels in the editor, we dont' :)
  homepageId: (state, getters) => {
    return get(getters.activeFunnel, 'settings.homepage');
  },
  notFoundId: (state, getters) => {
    return get(getters.activeFunnel, 'settings.not_found_page');
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
