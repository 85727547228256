import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'

import toMutation from '../to-mutation'
import { getPopupResource } from '../../service'
import { popupResourceLoaded, activeDocumentChanged } from '../action-creators'
import arrayMove from '@converdy/utils/array-move'
import arrayRemove from '@converdy/utils/array-remove'
import set from 'lodash/set'

import {
	POPUP_RESOURCE_INSERTED,
	POPUPS_LOADED,
	POPUP_SORTED,
	POPUP_RESOURCE_LOADED,
	POPUP_SETTINGS_UPDATED,
	POPUP_DELETED,
} from '../action-types'

const ADD_POPUP = 'ADD_POPUP'
const DELETE_POPUP = 'DELETE_POPUP'
const NEW_POPUP = 'NEW_POPUP'
const UPDATE_POPUP_SETTINGS = 'UPDATE_POPUP_SETTINGS'
const SORT_POPUP = 'SORT_POPUP'

export const initial = {
	byId: {},
	allIds: [],
	visiblePopupId: false,
}

export const state = cloneDeep(initial)

function handlePopupDeleted ({ commit }, { payload: { popupId } }) {
	commit(DELETE_POPUP, popupId)
}

function handlePopupsLoaded ({ commit }, { payload: { popups } }) {
	popups.forEach(
		popup => {
			const { slots, ...rest } = popup
			commit('ADD_POPUP', rest)
		}
	)
}

function handlePopupResourceLoaded ({ commit, dispatch }, { payload: { popup } }) {

  const { slots, ...rest } = popup
	
	commit(ADD_POPUP, rest)

	dispatch(activeDocumentChanged({
		id: popup.id,
		type: 'popup'
	}))
  
}

async function handlePopupInserted ({ dispatch }, {payload: { funnelId, title, resourceId }}) {

	let response = {}

	response = await getPopupResource(resourceId)

	const { data: { resource, elements } } = response 

	resource.settings.title = title

	dispatch(
		popupResourceLoaded({
			elements,
			popup: Object.assign(resource, { funnelId })
		})
	)
}

// function handlePopupCreated ({ commit }, { payload: { popup } }) {
// 	commit(ADD_POPUP, popup)
// 	router.push({ params: { popupId: popup.id } })
// }

export const actions = {

	[ POPUP_DELETED ]: handlePopupDeleted,

	[ POPUPS_LOADED ]: handlePopupsLoaded,

	[ POPUP_RESOURCE_LOADED ]: handlePopupResourceLoaded,

	[ POPUP_RESOURCE_INSERTED ]: handlePopupInserted,

	[ POPUP_SORTED ]: toMutation(SORT_POPUP),

	// [ POPUP_CREATED ]: handlePopupCreated,

	[ POPUP_SETTINGS_UPDATED ]: toMutation(UPDATE_POPUP_SETTINGS)
}

export const mutations = {
	
	[ ADD_POPUP ] (state, popup ) {

		Vue.set(state.byId, popup.id, popup)
		state.allIds.push(popup.id)

	},
	
	[ DELETE_POPUP ] (state, id) {

		Vue.delete(state.byId, id)
		state.allIds = arrayRemove(state.allIds, id)

	},

	[ NEW_POPUP ] (state, popup) {
		
		/* Todo discuss, are we going to normalize components under page? */
		if(popup.components) {
			Vue.set(state,'components', {...state.components, ...popup.components})
			delete popup.components
		}

		// Vue.set(state.pages, page.id, page)
		Vue.set(state.byId, popup.id, popup)
		state.allIds.push(popup.id)
		
	},

  [ SORT_POPUP ] (state, {newIndex, oldIndex}) {
		state.allIds = arrayMove(state.allIds, oldIndex, newIndex)
	},


	[ UPDATE_POPUP_SETTINGS ] (state, { id, value, path }) {

		const {byId: { [ id ]: popup } } = state 
		const newProperties = set(popup, path, value)
	
		/* reactivity is hard */
		Vue.set(state.byId, id, cloneDeep(newProperties))

	}
}

export const getters = {

	// visiblePopup: (state, getters) => getters.popupById(state.visiblePopupId),

	popupById (state, getters) {
		return function (id) {
			const resource = state.byId[id]

			if (resource) {
				return Object.assign(
					{},
					resource,
					{ slots: getters.slotsById(id) }
				)
			}
		}
	},

	popupsByFunnelId (state, getters) {
		return function (funnelId) {
			return state.allIds.map((id) => getters.popupById(id))
		}
	}
}

export default {
	state,
	actions,
	mutations,
	getters
}
